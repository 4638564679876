import React from "react";

const SearchCardSkeleton = () => {
  return (
    <div className="border border-gray-300 p-4 rounded-lg w-[48%] h-[28rem] animate-pulse">
      <div className="flex flex-col h-full">
        <div className="w-full h-[60%] rounded-lg bg-gray-300" />
        <div className="flex flex-col py-4 gap-y-3 h-[40%]">
          <div className="h-4 rounded-lg w-3/4 bg-gray-200"></div>
          <div className="h-3 rounded-lg w-1/2 bg-gray-200"></div>
          <div className="h-3 rounded-lg w-1/2 bg-gray-200"></div>
          <div className="rounded-lg p-4 flex items-center gap-x-2 w-[35%] ml-auto  bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
};

export default SearchCardSkeleton;
