const GeneralInfo = [
  {
    title: "Video Title",
    description: "What is the video title?",
    placeholder: "Samsung s23 unboxing",
    name: "title",
    maxLength: 120,
    required: true,
  },
  {
    title: "Video Topic",
    description: "What is the video about?",
    placeholder: "Cars & Robots",
    name: "video_topic",
    maxLength: 150,
    required: true,
  },
  {
    title: "Target Audience",
    description: "Who is the video for?",
    placeholder: "“Fitness gyms...”",
    name: "target_audience",
    maxLength: 100,
    required: true,
  },
];
const backgroundInfo = [
  {
    title: "Keywords",
    description: "Enter some keywords separated by comma for better SEO",
    placeholder: "unboxing,samsung,mobile...",
    name: "keywords",
    maxLength: 4000,
    required: false,
  },
  {
    title: "Conclusion",
    description: "What's the conclusion?",
    placeholder: "100% loss in body weight.",
    name: "conclusion",
    maxLength: 4000,
  },
];
export { GeneralInfo, backgroundInfo };
