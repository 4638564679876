import React from "react";

const TagSkeleton = () => {
  return (
    <div className="w-full border border-gray-200 rounded-lg p-6 flex flex-col gap-y-5 animate-pulse">
      <div className="h-4 rounded-lg bg-gray-200 w-[35%]"></div>
      <div className="flex gap-x-3 gap-y-3 items-center flex-wrap">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => {
          return (
            <div
              key={i}
              className="px-16 py-4 rounded-lg bg-gray-200 text-gray-600 h-3 w-fit"
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default TagSkeleton;
