import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

// icons
import { LiaPencilRulerSolid } from "react-icons/lia";
import { MdOutlineManageSearch } from "react-icons/md";
import { PiMagicWandFill } from "react-icons/pi";
import { FaRegUserCircle } from "react-icons/fa";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineSupport } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";

import SidebarRight from "./SidebarRight";

import { Modal } from "./Modal";
import { toast } from "sonner";
import CreditsModal from "./CreditsModal";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import ConfirmationModal from "./ConfirmationModal";
import { handlePortalSession, handleEndFreeTrial } from "../redux/actions/paymentActions";
import { creditsData as creditsDataAction } from "../redux/actions/paymentActions";
import { fetchUser } from "../redux/actions/auth";
import Avatar from "../assets/images/Avatar.png";

const Popup = ({ onClose }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const [upgradeModal, setUpgradeModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showCreditsModal, setShowCreditsModal] = useState(false);

    const creditsData = useSelector((state) => state.stripe.creditsData);
    const currentCredits = creditsData?.credit_balance;
    const total_purchased_credits = creditsData?.credit_total;
    const [loading, setLoading] = useState(false);

    const logoutHandler = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        dispatch({
            type: "LOGOUT",
        });

        navigate("/signin");
    }

    const creditsModalHandler = () => {
        setShowCreditsModal(true);
    }

    const LowerData = [
        {
            title: "Support",
            redirectLink: "dashboard/support",
            selectedSvgCode: <HiOutlineSupport className="w-5 h-5 text-primary" />,
            unSelectedSvgCode: <HiOutlineSupport className="w-5 h-5" />,
        },
        {
            title: "Settings",
            redirectLink: "dashboard/settings",
            selectedSvgCode: <AiOutlineSetting className="w-5 h-5 text-primary" />,
            unSelectedSvgCode: <AiOutlineSetting className="w-5 h-5" />,
        },
    ];

    const upgradeModalHandler = async () => {
        try {
            setLoading(true);
            await handleEndFreeTrial()
            var result = await fetchUser()
            dispatch(creditsDataAction());
            // Introduce a delay of 10 seconds
            await new Promise(resolve => setTimeout(resolve, 6000));
            toast.success("Upgrade Successful, It might take a few minutes to reflect in your account.")
        } catch (error) {
            console.log(error, "Error in get settings")
        } finally {
            setLoading(false);
            setUpgradeModal(false);
        }
    }

    const logoutClickHandler = async () => {
        // e.stopPropagation();
        setShowLogoutModal(true);
        // onClose();
    }

    console.log(showLogoutModal, "showLogoutModal")
    return (
        <>
            {showCreditsModal && <CreditsModal setShowCreditsModal={setShowCreditsModal} />}
            {showLogoutModal && (
                <Modal handleClose={() => setShowLogoutModal(false)}>
                    <div className="flex flex-col space-y-3">
                        <h1 className="title">Are you sure?</h1>
                        <p className="text-gray-500 text-center">
                            You will be Logged out once you click on the logout button.
                        </p>
                        <div className="flex items-center ml-auto text-sm pt-1 font-medium space-x-3">
                            <button
                                className="flex cursor-pointer items-csenter space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"
                                onClick={() => setShowLogoutModal(false)}
                            >
                                <p>Cancel</p>
                            </button>
                            <button
                                className="flex cursor-pointer items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
                                onClick={() => logoutHandler()}
                            >
                                <p>Logout</p>
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            <div className="popup bg-white shadow-md rounded-md  fixed bottom-20 right-4 z-49">
                <ul className=" px-3 space-y-1">
                    {LowerData.map((item, index) => (
                        <li
                            key={index}
                            className={`flex items-center gap-x-3 py-1.5 rounded-lg cursor-pointer transition-all duration-200 ease-in px-5`}
                            onClick={() => {
                                // if (item.title === "Settings") {
                                //   handlePortalSession()
                                //   return;
                                // }
                                onClose();
                                navigate(`/${item.redirectLink}`);
                                document
                                    .getElementById("sidebar")
                                // .classList.toggle("invisible");
                            }}
                        >
                            {item.unSelectedSvgCode}
                            {
                                <p
                                    className={`text-[17px] font-Inter font-[500] ease-in duration-300 rounded-lg`}
                                >
                                    {item.title}
                                </p>
                            }{" "}

                        </li>
                    ))}
                    <li>
                        <hr className="bg-gray-100 h-[0.5px]" />
                    </li>
                    <li className={``}>
                        {
                            user?.freeTrialStatus === 'active' && (
                                <button
                                    className={`bg-primary w-full text-white rounded-lg outline-none py-2 flex items-center gap-x-2 px-6`}
                                    onClick={() => creditsModalHandler()}
                                >
                                    <AiOutlinePlus />
                                    {
                                        <p className="px-1">Buy Credits</p>
                                    }
                                </button>
                            )
                        }

                        {
                            user?.freeTrialStatus !== 'active' && (
                                <button
                                    className={`bg-primary w-full text-white rounded-lg outline-none py-2 flex items-center gap-x-2 px-6'}`}
                                    onClick={() => setUpgradeModal(true)}
                                >
                                    Skip Free Trial
                                </button>

                            )
                        }

                        {
                            user?.freeTrialStatus === 'trialing' && (
                                <p className="text-sm text-Inter text-center mt-1.5 text-gray-700">Free trial ends on
                                    <span className="font-semibold text-gray-600" style={{
                                        fontSize: "smaller"
                                    }}> {new Date(user?.trialEndDate).toLocaleDateString('en-GB')}</span>
                                    .</p>
                            )
                        }
                        <div className="mt-2 pt-2 rounded-lg" style={{
                            paddingLeft: '12px',
                        }}>
                            <div className="flex item-center gap-x-2">
                                <div className="">
                                    <svg
                                        width="35"
                                        height="35"
                                        className="bg-primarySupport p-2 rounded-full"
                                        viewBox="0 0 12 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.33332 1.51314V4.26683C7.33332 4.6402 7.33332 4.82688 7.40599 4.96949C7.4699 5.09493 7.57189 5.19692 7.69733 5.26083C7.83994 5.3335 8.02662 5.3335 8.39999 5.3335H11.1537M11.3333 6.65898V11.4668C11.3333 12.5869 11.3333 13.147 11.1153 13.5748C10.9236 13.9511 10.6176 14.2571 10.2413 14.4488C9.81348 14.6668 9.25343 14.6668 8.13332 14.6668H3.86666C2.74655 14.6668 2.1865 14.6668 1.75868 14.4488C1.38235 14.2571 1.07639 13.9511 0.884643 13.5748C0.666656 13.147 0.666656 12.5869 0.666656 11.4668V4.5335C0.666656 3.41339 0.666656 2.85334 0.884643 2.42552C1.07639 2.04919 1.38235 1.74323 1.75868 1.55148C2.1865 1.3335 2.74655 1.3335 3.86666 1.3335H6.00784C6.49702 1.3335 6.74161 1.3335 6.97179 1.38876C7.17586 1.43775 7.37095 1.51856 7.54989 1.62822C7.75173 1.7519 7.92468 1.92485 8.27058 2.27075L10.3961 4.39624C10.742 4.74214 10.9149 4.91509 11.0386 5.11693C11.1483 5.29587 11.2291 5.49096 11.2781 5.69503C11.3333 5.92521 11.3333 6.1698 11.3333 6.65898Z"
                                            stroke="#FF4405"
                                            stroke-width="1.33333"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                {
                                    <div>
                                        <p
                                            className={`text-[17px] leading-5 font-Inter font-[500] ease-in duration-300 rounded-lg text-[#344054] `}
                                        >
                                            Credits
                                        </p>
                                        <p className="text-sm  text-[#475467]">
                                            Balance: {currentCredits.toLocaleString("en-US")}
                                        </p>
                                    </div>
                                }
                            </div>
                            {
                                <div className="grid grid-cols-12 gap-x-2 w-10/12 ml-2 mt-2">
                                    <div className="w-full bg-[#EAECF0] rounded-full h-2 mb-4 col-span-10">
                                        <div
                                            className="bg-primary h-2 rounded-full "
                                            style={{
                                                width: `${(currentCredits / total_purchased_credits) * 100
                                                    }%`,
                                            }}
                                        ></div>
                                    </div>
                                    <p className="leading-3 text-sm -mt-1 font-[500] text-[#344054] col-span-2">
                                        {Math.floor(
                                            (currentCredits / total_purchased_credits) * 100
                                        ).toFixed(0) > 0
                                            ? Math.floor(
                                                (currentCredits / total_purchased_credits) * 100
                                            ).toFixed(0)
                                            : 0}
                                        %
                                    </p>
                                </div>
                            }
                        </div>

                    </li>
                    <li
                        className={`flex items-center gap-x-3 py-1.5 rounded-lg cursor-pointer transition-all duration-200 ease-in px-5`}
                        onClick={(e) => {
                            e.stopPropagation();
                            logoutClickHandler();
                        }}>
                        <p
                            className={`text-[17px] font-Inter font-[500] ease-in duration-300 rounded-lg`}
                        >
                            Logout
                            {/* {
                                <svg
                                    width="18"
                                    height="18"
                                    className="mt-2 cursor-pointer"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                                        stroke="#667085"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            } */}
                        </p>
                    </li>
                </ul>
            </div>
            {
                upgradeModal && (
                    <ConfirmationModal
                        heading="Upgrade Your Experience Instantly!"
                        message="End your free trial now and seamlessly transition into a subscription. Elevate your experience and enjoy additional credits instantly. It will cost you $100."
                        onCancel={() => { setUpgradeModal(false) }}
                        onConfirm={() => { upgradeModalHandler() }}
                        loading={loading}
                    />
                )
            }
        </>
    );
};

const BottomBar = () => {
    const navigate = useNavigate();

    const [showPopup, setShowPopup] = useState(false);

    const user = useSelector((state) => state.auth.user);

    const handleProfileClick = () => {
        setShowPopup(!showPopup);
    };

    useEffect(() => {
    }, []);

    console.log(showPopup, "showPopup")
    return (
        // <div
        // className={`fixed bottom-0 w-full z-50 ${
        //     show ? "animate-fade-in" : "animate-fade-out"
        // }`}
        // ></div>
        <div className="">
            <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
                <div id="tabs" className="flex justify-between">
                    <div onClick={
                        () => {
                            setShowPopup(false);
                            navigate("/dashboard")
                        }
                    } className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                        <MdOutlineManageSearch className="inline-block mb-1 w-[25px] h-[25px]" />
                        <span className="tab tab-another block text-xs">Idea Research</span>
                    </div>
                    <div onClick={
                        () => {
                            setShowPopup(false);
                            navigate("/dashboard/script-generator")
                        }
                    } className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                        <PiMagicWandFill className="inline-block mb-1 w-[25px] h-[25px]" />
                        <span className="tab tab-another block text-xs">Script Generator</span>
                    </div>
                    <div onClick={
                        () => {
                            setShowPopup(false);
                            navigate("/dashboard/aioptimization")
                        }
                    } className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                        <LiaPencilRulerSolid className="inline-block mb-1" />
                        <span className="tab tab-another block text-xs">AI Video Optimization</span>
                    </div>
                    <div onClick={handleProfileClick} className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                        <FaRegUserCircle className="inline-block mb-1 w-[25px] h-[25px]" />
                        <span className="tab tab-home block text-xs">{
                            user && user?.name
                        }</span>
                    </div>
                </div>
            </section>
            {showPopup && <Popup onClose={() => {
                setShowPopup(false);
            }} />}
        </div>
    );
}

export default BottomBar;