import { combineReducers } from "redux";

import auth from "./auth";
import scriptForm from "./scriptForm";
import audit from "./audit";
import stripeReducer from "./stripe";
import ideas from "./ideaReducers"
import scripts from "./scriptReducers"

export default combineReducers({
  auth: auth,
  scriptForm: scriptForm,
  audit: audit,
  stripe: stripeReducer,
  ideas: ideas,
  scripts: scripts,
});
