import React, { useState } from 'react';
import { handlePortalSession } from '../redux/actions/paymentActions';
import { useSelector, useDispatch } from 'react-redux';
import { handlePayment } from '../redux/actions/paymentActions';

const staticPlans = [
  {
    name: 'Standard',
    price: process.env.REACT_APP_BASE_PLAN_PRICE,
    features: [
      `AI Script Generators: Create engaging scripts using AI`,
      "AI Video Optimization: Enhance your videos with AI-driven tools",
      "No Idea Research: Focus on creation, let AI handle the research",
      `Access to ${process.env.REACT_APP_BASE_PLAN_CREDITS} credits`
    ],
    popular: false,
    plan: 'Standard',
  },
  {
    name: 'Professional',
    price: process.env.REACT_APP_ADVANCE_PLAN_PRICE,
    features: [
      "All Standard Plan Features",
      "Ideal for professionals and growing businesses",
      "Enhanced support and resources",
      `Access to ${process.env.REACT_APP_ADVANCE_PLAN_CREDITS} credits`
    ],
    popular: true,
    plan: 'Professional',
  },
  {
    name: 'Premium',
    price: process.env.REACT_APP_PREMIUM_PLAN_PRICE,
    features: [
      "All Professional Plan Features",
      "Tailored for large-scale operations and enterprises",
      "Priority support and advanced tools",
      `Access to ${process.env.REACT_APP_PREMIUM_PLAN_CREDITS} credits`

    ],
    popular: false,
    plan: 'Premium',
  },
];

const Settings = () => {
  const [loading, setLoading] = useState(null);
  const [manageBtnLoading, setManageBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const currentPlan = useSelector((state) => state.stripe.currentPlan);

  const user = useSelector((state) => state.auth.user);
  const handleStripe = () => {
    // Your handleStripe logic here
  };

  const buyPlan = (plan) => {
    try {
      // if (user && user.paymentStatus === 'failed') {
      //   dispatch(redirectCustomerPortal(user.customerId))
      //   return
      // }

      console.log(plan, "plan")
      setLoading(plan);
      handlePortalSession(plan, setLoading);
      // dispatch(handlePayment(plan));
    } catch (err) {
      console.log("Something went wrong in buying plan");
      console.log(err);
    }
  };


  return (
    <>
      <div
        className={`px-12 fade-in py-4 flex w-full justify-between items-center font-Inter`}
      >
        <div className="flex items-center space-x-3 w-[60%]">
          <p className="title">Payment Plans</p>
        </div>
        {/* <button
          className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
          onClick={() => navigate("/copy-generator/create-copy")}
        >
          <AiOutlinePlus /> <p>Create New Copy</p>
        </button> */}
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div className="py-4 w-full min-h-screen overflow-hidden">
        <div className='w-full flex justify-between items-center px-8'>
          <p className="text-lg font-Inter font-[600] text-[#101828] ease-in duration-300 fade-in">
            Payment Plans
          </p>
          <button
            onClick={handleStripe}
            type="text"
            className="inline-flex items-center gap-x-2 text-[14px] font-semibold font-Inter px-4 rounded-lg border-[1px] border-[#D0D5DD] py-2 shadow-leadButton hover:shadow-navButton"
          >
            {/* Your SVG icon here */}
            <p
              onClick={() => {
                setManageBtnLoading(true);
                handlePortalSession("", setManageBtnLoading);
              }}
              className="text-sm font-semibold text-center text-[#344054]"
            >
              {manageBtnLoading ? 'Loading...' : 'Manage Subscription'}
            </p>
          </button>
        </div>

        <section className="text-gray-600 body-font overflow-hidden">
          <div className="container px-8 mx-auto">
            <div className="flex flex-wrap -m-4">
              {staticPlans.map((item, key) => (
                <div key={key} className="p-4 xl:w-1/4 md:w-1/2 w-full">
                  <div className="h-full p-6 relative rounded-lg border-[1px] flex flex-col overflow-hidden">
                    {item.popular && (
                      <span className="bg-primary border-primary text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                        POPULAR
                      </span>
                    )}
                    <h2 className="text-sm tracking-widest title-font mb-1 font-medium text-Inter">
                      {item.name} Plan
                    </h2>
                    <h1 className="text-5xl text-gray-900 leading-none flex items-center text-Inter pb-4 mb-4 border-b border-gray-200">
                      <span>${item.price}</span>
                      <span className="text-lg ml-1 font-normal text-gray-500 text-Inter">/mo</span>
                    </h1>

                    <div className="mt-2.5 space-y-3 mb-4">
                      {item.features.map((feature, key) => (
                        <p key={key} className="flex items-center text-gray-600 text-Inter">
                          <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-orange-100 text-white rounded-full flex-shrink-0">
                            {/* Your SVG icon here */}
                          </span>
                          {feature}
                        </p>
                      ))}
                    </div>
                    {console.log(loading, item.plan, currentPlan)}
                    {loading === item.plan ? (
                      <button
                        disabled
                        type="button"
                        className="mx-auto mt-4 text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded"
                      >
                        {/* Your loading animation here */}
                        Loading...
                      </button>
                    ) : currentPlan === item.plan ? (
                      <button
                        className="mt-auto font-semibold font-Inter text-sm block w-full rounded-lg transition mx-auto duration-200 ease-in bg-primary hover:bg-primary py-2.5 text-white"
                      >
                        Current Plan
                      </button>
                    ) : (
                      < button
                        disabled={loading !== null}
                        onClick={() => buyPlan(item.plan)}
                        className={`mt-auto mx-auto text-primary text-Inter border-primary border-[1px] py-2 px-4 w-full font-semibold focus:outline-none hover:bg-priborder-primary rounded`}
                      >
                        Buy
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section >
      </div >
    </>
  );
};

export default Settings;
