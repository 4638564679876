import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const createScriptRecord = (formDetails) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.post('/createScriptRecord', { formDetails });
        if (status === 200) {

            if (data?.status === false) {
                toast.error(data?.message);
                return false;
            }

            // dispatch({
            //     type: "SET_SCRIPT_ID",
            //     payload: data?.id,
            // });
            return data?.id
        }

    }
    catch (error) {
        toast.error("Could not create Script Record!")
        return false
    }
}

export const deleteScript = (id) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.post('/deleteScript', {
            id
        })
        if (status) {
            toast.success("Script deleted successfully!")
        }

        if (status === 200) {
            dispatch({
                type: "GET_GENERATED_SCRIPTS",
                payload: data?.data,
            });
            return true
        }

    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Something went wrong!")
        return false
    }

}


export const getScriptResult = (id) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.get('/getScriptResult', {
            params: { id },
        });
        if (status === 200) {
            dispatch({
                type: "SET_SCRIPT_RESULT",
                payload: {
                    scriptId: data?.scriptId,
                    data: data?.result,
                }
            });

            dispatch({
                type: "SET_FORM_DETAILS",
                payload: {
                    title: data?.title,
                    video_type: "",
                    video_topic: "",
                    target_audience: "",
                    problem: "",
                    desired_result: "",
                    // conclusion: "",
                    tone: "",
                }
            });
            return true
        }

    } catch (error) {
        console.log(error, "Error in get settings")
        return false
    }

}

export const fetchLiveData = (scriptId, setLoading) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.post('/fetchLiveScript', {
            id: Number(scriptId)
        })

        if (status === 200) {
            dispatch({
                type: "SET_LIVE_STREAM_DATA",
                payload: data
            })
        }

        return {
            status: true,
            datalength: data.data?.length,
            isStreamEnded: data.isStreamEnded
        }

    } catch (error) {
        console.log(error, "Error in get settings")
        return {
            status: false,
            datalength: 0
        }
    }
}