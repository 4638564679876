const GeneralInfo = [
  {
    title: "Video Topic",
    description: "What is the video about?",
    placeholder: "Cars & Robots",
    name: "video_topic",
    maxLength: 150,
    required: true,
  },
  {
    title: "Target Audience",
    description: "Who is the video for?",
    placeholder: "“Fitness gyms...”",
    name: "target_audience",
    maxLength: 100,
    required: true,
  },
  {
    title: "Tone",
    description: "What's the tone of video?",
    placeholder: "“Clickbaity”",
    name: "tone",
    maxLength: 150,
    required: true,
  },
];
const backgroundInfo = [
  {
    title: "Additional Information",
    description: "Some additional information about the video.",
    placeholder: "In this video...",
    name: "outline",
    maxLength: 4000,
    required: false,
  },
  // {
  //   title: "Conclusion",
  //   description: "What's the conclusion?",
  //   placeholder: "100% loss in body weight.",
  //   name: "conclusion",
  //   maxLength: 200,
  // },
];
export { GeneralInfo, backgroundInfo };
