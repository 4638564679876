import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { isExpired } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserFirst } from '../redux/actions/auth';
import { toast } from 'sonner';

const PrivatePaymentRoute = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [haveAccess, setHaveAccess] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchUserFirst());
            } catch (error) {
                // Handle error if user data cannot be fetched
                toast.error('Failed to fetch user data');
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        // Update haveAccess when user data changes
        if (user) {
            // const haveAccess = user.haveAccess;
            const plan_id = user?.plan_id;
            console.log("line 32 payment user status", user)
            console.log("line 32", user?.paymentStatus)
            if (!plan_id) {
                setHaveAccess(false);
            } else {
                // const dateFromString = new Date(subscriptionEnd);
                // const currentDate = new Date();
                // setHaveAccess(dateFromString > currentDate);
            }

            console.log(haveAccess)
        }
    }, [user]);

    console.log("inside private router payment", haveAccess)

    return haveAccess ? <Outlet /> : <Navigate to="/payment-options" />;
};

export default PrivatePaymentRoute;
