import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const AreaChartWithCustomTooltip = (props) => {
    // Destructure data from props
    const { data: chartDataProps } = props;

    console.log(chartDataProps)
    const valueProperty = props.valueProperty || 'views';
    const labels = chartDataProps?.map(d => d.id).reverse();
    const data = chartDataProps?.map(d => d[valueProperty]).reverse();
    const extraData = chartDataProps?.map(d => d.title).reverse();

    const valueFormatter = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1) + "B";
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + "M";
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + "k";
        } else {
            return num?.toString();
        }
    };

    const chartData = {
        labels,
        datasets: [{
            label: valueProperty + ' for last 25 Videos',
            data,
            backgroundColor: 'rgba(255, 242, 242, 1)', // Light red background
            borderColor: 'red', // Red border
            borderWidth: 2,
            fill: true,
            tension: 0.4,
            pointRadius: 0,
        }]
    };

    const chartOptions = {
        plugins: {
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (context) {
                        const label = `ID: ${labels[context.dataIndex]}`;
                        const value = valueFormatter(context.raw);
                        const extraInfo = extraData[context.dataIndex];
                        return `${label}, Views: ${value}, Title: ${extraInfo}`;
                    }
                }
            }
        },
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false, // This will remove vertical grid lines
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return valueFormatter(value);
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    };
    

    return (
        <div className="text-sm flex flex-col space-y-5 border border-gray-300 rounded-lg py-8 px-3 h-[400px] w-full">
            <h1 className="text-lg font-semibold ml-3 text-gray-800">{props.title}</h1>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default AreaChartWithCustomTooltip;
