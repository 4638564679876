import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.png";
import favicon from "../assets/images/favicon.png";
import Avatar from "../assets/images/Avatar.png";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import smallLogo from "../assets/images/small_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "./Modal";
import { toast } from "sonner";
import CreditsModal from "./CreditsModal";
import { handlePortalSession, handleEndFreeTrial } from "../redux/actions/paymentActions";
import { fetchUser } from "../redux/actions/auth";
// import 'tippy.js/dist/svg-arrow.css';
import ConfirmationModal from "./ConfirmationModal";
// icons
import { LiaPencilRulerSolid } from "react-icons/lia";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineSupport } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import { creditsData as creditsDataAction } from "../redux/actions/paymentActions";

export const Sidebar = (props) => {
  const { sidebarClosingStatus, setSidebarClosingStatus } = props;
  // const { user } = props;
  const user = useSelector((state) => state.auth.user);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [upgradeModal, setUpgradeModal] = useState(false);
  const currentRoute = location.pathname.slice(1);
  const [loading, setLoading] = useState(false);
  const [selectedLink, setSelectedLink] = useState("dashboard");
  const [logoHoverStatus, setLogoHoverStatus] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const creditsData = useSelector((state) => state.stripe.creditsData);

  const currentCredits = creditsData?.credit_balance || 0;
  const total_purchased_credits = Math.max(creditsData?.credit_total, 100);
  useEffect(() => {
    setSelectedLink(currentRoute);
  }, [currentRoute]);

  const UpperData = [
    {
      title: "Idea Research",
      redirectLink: "dashboard",
      selectedSvgCode: (
        <svg
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
        >
          <path
            d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.55055 12.4468 8.05071 12.9999 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z"
            fill="#FF0000"
          />
        </svg>
      ),
      unSelectedSvgCode: (
        <svg
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
        >
          <path
            d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.55055 12.4468 8.05071 12.9999 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z"
            fill="#344054"
          />
        </svg>
      ),
    },
    {
      title: "AI Script Generator",
      redirectLink: "dashboard/script-generator",
      selectedSvgCode: (
        <svg
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
        >
          <path
            d="M7.50006 3.6L5.00006 5L6.40006 2.5L5.00006 0L7.50006 1.4L10.0001 0L8.60006 2.5L10.0001 5L7.50006 3.6ZM19.5001 13.4L22.0001 12L20.6001 14.5L22.0001 17L19.5001 15.6L17.0001 17L18.4001 14.5L17.0001 12L19.5001 13.4ZM22.0001 0L20.6001 2.5L22.0001 5L19.5001 3.6L17.0001 5L18.4001 2.5L17.0001 0L19.5001 1.4L22.0001 0ZM13.3401 10.78L15.7801 8.34L13.6601 6.22L11.2201 8.66L13.3401 10.78ZM14.3701 5.29L16.7101 7.63C17.1001 8 17.1001 8.65 16.7101 9.04L5.04006 20.71C4.65006 21.1 4.00006 21.1 3.63006 20.71L1.29006 18.37C0.900059 18 0.900059 17.35 1.29006 16.96L12.9601 5.29C13.3501 4.9 14.0001 4.9 14.3701 5.29Z"
            fill="#FF0000"
          />
        </svg>
      ),
      unSelectedSvgCode: (
        <svg
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
        >
          <path
            d="M7.50006 3.6L5.00006 5L6.40006 2.5L5.00006 0L7.50006 1.4L10.0001 0L8.60006 2.5L10.0001 5L7.50006 3.6ZM19.5001 13.4L22.0001 12L20.6001 14.5L22.0001 17L19.5001 15.6L17.0001 17L18.4001 14.5L17.0001 12L19.5001 13.4ZM22.0001 0L20.6001 2.5L22.0001 5L19.5001 3.6L17.0001 5L18.4001 2.5L17.0001 0L19.5001 1.4L22.0001 0ZM13.3401 10.78L15.7801 8.34L13.6601 6.22L11.2201 8.66L13.3401 10.78ZM14.3701 5.29L16.7101 7.63C17.1001 8 17.1001 8.65 16.7101 9.04L5.04006 20.71C4.65006 21.1 4.00006 21.1 3.63006 20.71L1.29006 18.37C0.900059 18 0.900059 17.35 1.29006 16.96L12.9601 5.29C13.3501 4.9 14.0001 4.9 14.3701 5.29Z"
            fill="#344054"
          />
        </svg>
      ),
    },
    {
      title: "AI Video Optimization",
      redirectLink: "dashboard/aioptimization",
      selectedSvgCode: <LiaPencilRulerSolid className="w-5 h-5 text-primary" />,
      unSelectedSvgCode: <LiaPencilRulerSolid className="w-5 h-5" />,
    },
  ];

  const LowerData = [
    {
      title: "Support",
      redirectLink: "dashboard/support",
      selectedSvgCode: <HiOutlineSupport className="w-5 h-5 text-primary" />,
      unSelectedSvgCode: <HiOutlineSupport className="w-5 h-5" />,
    },
    {
      title: "Settings",
      redirectLink: "dashboard/settings",
      selectedSvgCode: <AiOutlineSetting className="w-5 h-5 text-primary" />,
      unSelectedSvgCode: <AiOutlineSetting className="w-5 h-5" />,
    },
  ];

  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    dispatch({
      type: "LOGOUT",
    });

    navigate("/signin");
  }

  const creditsModalHandler = () => {
    setShowCreditsModal(true);
  }

  const upgradeModalHandler = async () => {
    try {
      setLoading(true);
      await handleEndFreeTrial()
      var result = await fetchUser()
      dispatch(creditsDataAction());
      // Introduce a delay of 10 seconds
      await new Promise(resolve => setTimeout(resolve, 6000));
      toast.success("Upgrade Successful, It might take a few minutes to reflect in your account.")
    } catch (error) {
      console.log(error, "Error in get settings")
    } finally {
      setLoading(false);
      setUpgradeModal(false);
    }
  }


  return (
    <>
      {showCreditsModal && <CreditsModal setShowCreditsModal={setShowCreditsModal} />}
      {showLogoutModal && (
        <Modal handleClose={() => setShowLogoutModal(false)}>
          <div className="flex flex-col items-center justify-center z-50">
            <h1 className="title">Are you sure?</h1>
            <p className="text-gray-500 text-center">
              You will be Logged out once you click on the logout button.
            </p>
            <div className="flex items-center space-x-2 mt-5">
              <button
                className="flex items-center space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"
                onClick={() => setShowLogoutModal(false)}
              >
                <p>Cancel</p>
              </button>
              <button
                className="flex items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
                onClick={() => logoutHandler()}
              >
                <p>Logout</p>
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div
        id="sidebar"
        className={`w-full ${sidebarClosingStatus ? `max-w-[240px]` : `max-w-[80px]`
          }  z-49 fixed bg-white border-gray-200 border-r-[1.15px] ease-in-out duration-300`}
          style={
            {
              height: '100vh',
            }
          }
      >
        <div className="h-full relative">
          <div
            className="flex justify-between items-center px-4 mt-4 py-1"
            onMouseEnter={() => {
              if (!sidebarClosingStatus) {
                setLogoHoverStatus(true);
              }
            }}
            onMouseLeave={() => {
              if (!sidebarClosingStatus) {
                setLogoHoverStatus(false);
              }
            }}
          >
            <div
              className={`flex w-full items-center ${!sidebarClosingStatus ? "justify-center" : null
                }`}
            >
              {
                sidebarClosingStatus ? (
                  // <img src={logo} alt='logo' className='w-14 ml-1 py-1' />
                  <img src={logo} onClick={
                    () => {
                      navigate(`/dashboard`);
                    }
                  } className="w-3/4 h-auto cursor-pointer" />
                ) : (
                  <img
                    src={smallLogo}
                    className={`w-auto h-auto cursor-pointer ${logoHoverStatus && "hidden"}`}
                  />
                )
                // <img src={favicon} alt='logo' className={`w-7 mx-auto ml-2.5 ${logoHoverStatus && `hidden`}`} />
              }
            </div>
            <div
              onClick={() => setSidebarClosingStatus(!sidebarClosingStatus)}
              className={`${sidebarClosingStatus ? "p-1" : "p-0"}`}
            >
              {(sidebarClosingStatus || logoHoverStatus) && (
                <svg
                  className={`cursor-pointer ease-in duration-100 ${!sidebarClosingStatus && `rotate-180 `
                    } ${!sidebarClosingStatus && logoHoverStatus && `mr-4 h-7`}`}
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={(e) => {
                    if (window.innerWidth < 640) {
                      document
                        .getElementById("sidebar")
                        // .classList.toggle("invisible");
                    }
                  }}
                >
                  <path
                    d="M13 11L8 6L13 1M6 11L1 6L6 1"
                    stroke="#101828"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
          <hr className="bg-gray-200 h-[1.15px] mt-3" />

          <ul className="mt-4 px-3 space-y-2.5">
            {UpperData.map((item, index) => (
              <li
                key={index}
                className={`flex items-center py-1.5 rounded-lg cursor-pointer transition-all duration-200 ease-in gap-x-3 ${sidebarClosingStatus ? `pl-3 pr-1` : `px-3 ml-1 justify-center`
                  } ${selectedLink === item.redirectLink
                    ? ` bg-primary-light`
                    : `text-[#344054]`
                  }`}
                onClick={() => {
                  navigate(`/${item.redirectLink}`);
                  document
                    .getElementById("sidebar")
                    // .classList.toggle("invisible");
                }}
              >
                {sidebarClosingStatus ? (
                  selectedLink === item.redirectLink ? (
                    item.selectedSvgCode
                  ) : (
                    item.unSelectedSvgCode
                  )
                ) : (
                  <Tippy theme="custom" placement="right" content={item.title}>
                    {selectedLink === item.redirectLink
                      ? item.selectedSvgCode
                      : item.unSelectedSvgCode}
                  </Tippy>
                )}
                {sidebarClosingStatus && (
                  <p
                    className={`text-md font-Inter font-[500] ease-in duration-300 rounded-lg ${selectedLink === item.redirectLink
                      ? `text-primary`
                      : `text-[#344054]`
                      } `}
                  >
                    {item.title}
                  </p>
                )}
              </li>
            ))}
          </ul>

          <div className="absolute bottom-8 w-full">
            <ul className="mt-4 px-3 space-y-2.5">
              {LowerData.map((item, index) => (
                <li
                  key={index}
                  className={`flex items-center gap-x-3 py-1.5 rounded-lg cursor-pointer transition-all duration-200 ease-in ${sidebarClosingStatus ? `px-5` : `px-3 ml-1 justify-center`
                    } ${selectedLink.includes(item.redirectLink)
                      ? `bg-primary-light`
                      : `text-[#344054]`
                    }`}
                  onClick={() => {
                    // if (item.title === "Settings") {
                    //   handlePortalSession()
                    //   return;
                    // }
                    navigate(`/${item.redirectLink}`);
                    document
                      .getElementById("sidebar")
                      // .classList.toggle("invisible");
                  }}
                >
                  {sidebarClosingStatus ? (
                    selectedLink.includes(item.redirectLink) ? (
                      item.selectedSvgCode
                    ) : (
                      item.unSelectedSvgCode
                    )
                  ) : (
                    <Tippy theme="custom" placement="right" content={item.title}>
                      {selectedLink.includes(item.redirectLink)
                        ? item.selectedSvgCode
                        : item.unSelectedSvgCode}
                    </Tippy>
                  )}
                  {sidebarClosingStatus && (
                    <p
                      className={`text-[17px] font-Inter font-[500] ease-in duration-300 rounded-lg ${selectedLink.includes(item.redirectLink)
                        ? `text-primary`
                        : `text-[#344054]`
                        } `}
                    >
                      {item.title}
                    </p>
                  )}{" "}
                  
                </li>
              ))}
              <li>
                <hr className="bg-gray-100 h-[0.5px]" />
              </li>
              <li className={``}>
                {
                  user?.freeTrialStatus === 'active' && (
                    <button
                      className={`bg-primary w-full text-white rounded-lg outline-none py-2 flex items-center gap-x-2 ${sidebarClosingStatus ? 'px-6' : 'px-4'}`}
                      onClick={() => creditsModalHandler()}
                    >
                      <AiOutlinePlus />
                      {sidebarClosingStatus &&
                        <p className="px-1">Buy Credits</p>
                      }
                    </button>
                  )
                }

                {
                  user?.freeTrialStatus !== 'active' && (
                    <button
                      className={`bg-primary w-full text-white rounded-lg outline-none py-2 flex items-center gap-x-2 ${sidebarClosingStatus ? 'px-6' : 'px-4'}`}
                      onClick={() => setUpgradeModal(true)}
                    >
                      Skip Free Trial
                    </button>

                  )
                }

                {
                  user?.freeTrialStatus === 'trialing' && (
                    <p className="text-sm text-Inter text-center mt-1.5 text-gray-700">Free trial ends on
                      <span className="font-semibold text-gray-600" style={{
                        fontSize : "smaller"
                      }}> {new Date(user?.trialEndDate).toLocaleDateString('en-GB')}</span>
                      .</p>
                  )
                }
                <div className="mt-2 pt-2 rounded-lg" style={{
                  paddingLeft: '12px',
                }}>
                  <div className="flex item-center gap-x-2">
                    <div className="">
                      <svg
                        width="35"
                        height="35"
                        className="bg-primarySupport p-2 rounded-full"
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33332 1.51314V4.26683C7.33332 4.6402 7.33332 4.82688 7.40599 4.96949C7.4699 5.09493 7.57189 5.19692 7.69733 5.26083C7.83994 5.3335 8.02662 5.3335 8.39999 5.3335H11.1537M11.3333 6.65898V11.4668C11.3333 12.5869 11.3333 13.147 11.1153 13.5748C10.9236 13.9511 10.6176 14.2571 10.2413 14.4488C9.81348 14.6668 9.25343 14.6668 8.13332 14.6668H3.86666C2.74655 14.6668 2.1865 14.6668 1.75868 14.4488C1.38235 14.2571 1.07639 13.9511 0.884643 13.5748C0.666656 13.147 0.666656 12.5869 0.666656 11.4668V4.5335C0.666656 3.41339 0.666656 2.85334 0.884643 2.42552C1.07639 2.04919 1.38235 1.74323 1.75868 1.55148C2.1865 1.3335 2.74655 1.3335 3.86666 1.3335H6.00784C6.49702 1.3335 6.74161 1.3335 6.97179 1.38876C7.17586 1.43775 7.37095 1.51856 7.54989 1.62822C7.75173 1.7519 7.92468 1.92485 8.27058 2.27075L10.3961 4.39624C10.742 4.74214 10.9149 4.91509 11.0386 5.11693C11.1483 5.29587 11.2291 5.49096 11.2781 5.69503C11.3333 5.92521 11.3333 6.1698 11.3333 6.65898Z"
                          stroke="#FF4405"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    {sidebarClosingStatus && (
                      <div>
                        <p
                          className={`text-[17px] leading-5 font-Inter font-[500] ease-in duration-300 rounded-lg text-[#344054] `}
                        >
                          Credits
                        </p>
                        <p className="text-sm  text-[#475467]">
                          Balance: {currentCredits.toLocaleString("en-US")}
                        </p>
                      </div>
                    )}
                  </div>
                  {sidebarClosingStatus && (
                    <div className="grid grid-cols-12 gap-x-2 w-10/12 ml-2 mt-2">
                      <div className="w-full bg-[#EAECF0] rounded-full h-2 mb-4 col-span-10">
                        <div
                          className="bg-primary h-2 rounded-full "
                          style={{
                            width: `${(currentCredits / total_purchased_credits) * 100
                              }%`,
                          }}
                        ></div>
                      </div>
                      <p className="leading-3 text-sm -mt-1 font-[500] text-[#344054] col-span-2">
                        {Math.floor(
                          (currentCredits / total_purchased_credits) * 100
                        ).toFixed(0) > 0
                          ? Math.floor(
                            (currentCredits / total_purchased_credits) * 100
                          ).toFixed(0)
                          : 0}
                        %
                      </p>
                    </div>
                  )}
                </div>

              </li>
              <li>
                <hr className="bg-gray-100 h-[0.5px]" />
              </li>
              {/* buy credits button */}
            </ul>

            {/* <hr className="bg-gray-200 h-[1.15px] mt-1" /> */}

            <div className="flex justify-between item-center px-3 mt-3">
              <div className="flex items-center gap-x-3 px-2 truncate mr-3">
                {true ? (
                  <img
                    className="h-7 ml-2"
                    onClick={() => {
                      navigate(`/your-dex`);
                    }}
                    src={Avatar}
                  />
                ) : (
                  <div className="animate-pulse space-y-2">
                    <div className="h-9 w-9 bg-slate-400 rounded-full"></div>
                  </div>
                )}

                {sidebarClosingStatus &&
                  (user?.name ? (
                    <div className="">
                      <p className="leading-5">{user?.name}</p>
                      <p className="truncate leading-5">{user?.email}</p>
                    </div>
                  ) : (
                    <div className="animate-pulse space-y-2">
                      <div className="h-2 w-32 bg-slate-400 rounded "></div>
                      <div className="h-2 bg-slate-400 rounded "></div>
                    </div>
                  ))}
              </div>
              {sidebarClosingStatus && (
                <svg
                  onClick={() => {
                    setShowLogoutModal(true);
                  }}
                  width="18"
                  height="18"
                  className="mt-2 cursor-pointer"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>

      {
        upgradeModal && (
          <ConfirmationModal
            heading="Upgrade Your Experience Instantly!"
            message="End your free trial now and seamlessly transition into a subscription. Elevate your experience and enjoy additional credits instantly. It will cost you $100."
            onCancel={() => { setUpgradeModal(false) }}
            onConfirm={() => { upgradeModalHandler() }}
            loading={loading}
          />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
