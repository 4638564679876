const initState = {
    generatedData: [],
    generatedDataLoading: true,
    getResultLoading: false,
    loading: false,
    searchId: null,
    searchTerm: "",
    searchResult: [],
    deepUnlockStatus: false,
}


export default (state = initState, action) => {
    switch (action.type) {
        case "GET_GENERATED_DATA":
            return {
                ...state,
                generatedData: action.payload,
            };
        case "GET_SEARCH_RESULT":
            const { searchId, searchTerm, data } = action.payload;
            return {
                ...state,
                searchId: searchId !== undefined ? searchId : state.searchId,
                searchTerm: searchTerm !== undefined ? searchTerm : state.searchTerm,
                searchResult: data !== undefined ? data : state.searchResult,
            };
        case "CHANGE_LOADING_STATE":
            return {
                ...state,
                loading: !state.loading,
            };
        case "GENERATED_DATA_LOADING":
            return {
                ...state,
                generatedDataLoading: action.payload,
            };
        case "GET_RESULT_LOADING":
            return {
                ...state,
                getResultLoading: action.payload,
            };
        default:
            return state;
    }
}