const initialState = {
    creditsData: {
        credit_balance: 0,
        credit_total: 0,
    },
    showSubscriptionModal: false,
    currentPlan: null,
}

export default function stripeReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_STRIPE':
            return {
                ...state,
                creditsData: action.payload,
            };
        case "SUBSCRIPTION_MODAL":
            return {
                ...state,
                showSubscriptionModal: action.payload,
            };
        case 'UPDATE_CURRENT_PLAN':
            return {
                ...state,
                currentPlan: action.payload,
            };
        default:
            return state;
    }
}