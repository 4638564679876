import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { SearchData } from "./SearchData";
import { BsPencil } from "react-icons/bs"
import { useSelector } from "react-redux";
import _debounce from 'lodash/debounce';
import "../../assets/styles/style.css"

const ScriptGenerator = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');
  const [tableData, setTableData] = useState([]);
  const inventoryData = useSelector((state) => state.scripts.generatedScripts);

  useEffect(() => {
    document.title = "AI script generator - YTA Studio";
  }, []);

  useEffect(() => {
    const debouncedSearch = _debounce(() => {

      const filteredData = inventoryData.filter(item =>
        item.title.toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredData);
    }, 300);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [searchInput, inventoryData]);

  const isMobileScreen = window.innerWidth < 768;
  return (
    <div>
      {
        isMobileScreen ?
          <div className="px-5 py-3.5 font-Inter">
            <div className="flex justify-between">
            <p className="title">AI Script Generator</p>
              <div>
                <button
                  className={`bg-primary text-white rounded-xl outline-none py-1 px-3 flex items-center gap-x-2`}
                  onClick={() => navigate("/dashboard/create-script")}
                >
                  <p>Start Search</p>
                </button>
              </div>
            </div>
            {/* <hr className="bg-gray-200 h-[1.15px] mt-1" /> */}
            <div className={`flex justify-end mt-2`}>
              <input
                className="bg-[#F9FAFB] rounded-lg w-full placeholder-[#6B7280] outline-none py-1 px-3 border border-[#E5E7EB]"
                placeholder="Search"
                maxLength={200}
                onChange={(e) => setSearchInput(e.target.value)}
              />

            </div>
          </div>
          :
          <div className="px-5 py-3.5 flex w-full justify-between items-center font-Inter">
            <div className={`flex items-center space-x-3 ${!isMobileScreen && "w-[60%]"}`}>
              <p className="title">AI Script Generator</p>
              <input
                className="bg-[#F9FAFB] rounded-full w-1/2 placeholder-[#6B7280] outline-none py-2 px-5 border border-[#E5E7EB]"
                placeholder="Search"
                maxLength={200}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <button
              className={`bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2 ${!isMobileScreen && "topBarBtn"}`}
              onClick={() => navigate("/dashboard/create-script")}
            >
              <BsPencil /> <p>Create Script</p>
            </button>
          </div>
      }
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <SearchData tableData={tableData} />
    </div>
  );
};

export default ScriptGenerator;
