import React, { useEffect } from "react";

const Support = () => {


  return (
    <div className={`px-5 py-3.5 flex justify-between items-center font-Inter`}>
      <div className="flex items-center space-x-3 w-[60%]">
        <p className="title">Support</p>

      </div>

    </div>
  )
};

export default Support;
