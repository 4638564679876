import React from "react";

const TitleSkeleton = () => {
  return (
    <div className="w-full border border-gray-200 rounded-lg p-6 flex flex-col gap-y-5 animate-pulse">
      <div className=" h-5 rounded-lg bg-gray-200 w-[35%]"></div>
      <div className="flex gap-y-3 flex-wrap flex-col">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((e, i) => {
          return (
            <div
              key={i}
              className="px-5 py-2 rounded-lg bg-gray-200 text-gray-600 h-8 w-[60%]"
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default TitleSkeleton;
