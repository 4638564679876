import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Modal } from "./Modal";
import { handleOneTimePayment } from "../redux/actions/paymentActions";
import { BsCoin } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";

const CreditsModal = ({ setShowCreditsModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disableButton, setDisableButton] = useState(false);
    const [addonConfirmation, setAddonConfirmation] = useState(false);

    const creditsData = [
        {
            credits: 10000,
            amount: 40,
            priceId: "price_1OART3IzZQLABtk242PrGqqb"
        },
        {
            credits: 25000,
            amount: 100,
            priceId: "price_1OART3IzZQLABtk27rCv9pvE"
        },
        {
            credits: 40000,
            amount: 150,
            priceId: "price_1OART3IzZQLABtk24ws6LnVH"
        }
    ]

    return (
        <>
            <Modal width={"w-1/2"} handleClose={() => setShowCreditsModal(false)}>
                <div className="flex  item-center gap-x-5">
                    <svg
                        width="56"
                        height="56"
                        viewBox="0 0 56 56"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
                        <path
                            d="M20.5 38V33M20.5 23V18M18 20.5H23M18 35.5H23M29 19L27.2658 23.5089C26.9838 24.2421 26.8428 24.6087 26.6235 24.9171C26.4292 25.1904 26.1904 25.4292 25.9171 25.6235C25.6087 25.8428 25.2421 25.9838 24.5089 26.2658L20 28L24.5089 29.7342C25.2421 30.0162 25.6087 30.1572 25.9171 30.3765C26.1904 30.5708 26.4292 30.8096 26.6235 31.0829C26.8428 31.3913 26.9838 31.7579 27.2658 32.4911L29 37L30.7342 32.4911C31.0162 31.7579 31.1572 31.3913 31.3765 31.0829C31.5708 30.8096 31.8096 30.5708 32.0829 30.3765C32.3913 30.1572 32.7579 30.0162 33.4911 29.7342L38 28L33.4911 26.2658C32.7579 25.9838 32.3913 25.8428 32.0829 25.6235C31.8096 25.4292 31.5708 25.1904 31.3765 24.9171C31.1572 24.6087 31.0162 24.2421 30.7342 23.5089L29 19Z"
                            stroke="#039855"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <rect
                            x="4"
                            y="4"
                            width="48"
                            height="48"
                            rx="24"
                            stroke="#ECFDF3"
                            stroke-width="8"
                        />
                    </svg>
                    <div>
                        <div className="">
                            <p className="text-lg font-semibold text-Inter">
                                Credit Addons                </p>
                            <p className="text-sm text-secondarySupport text-Inter">
                                Choose any plan to buy more credits.
                            </p>
                        </div>
                    </div>
                </div>
                <table
                    className="min-w-full mt-3 my-2 bg-white shadow-sm rounded-lg overflow-hidden"
                    style={{
                        textAlign: 'center',
                    }}
                >

                    <thead class="bg-gray-200">

                        <tr>
                            <th class="py-2 px-4">Credits</th>
                            <th class="py-2 px-4">Amount</th>
                            <th class="py-2 px-4"></th>
                            <th class="py-2 px-4">Action</th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-700">
                        {
                            creditsData.map((credit, index) => {
                                return (
                                    <tr>
                                        <td class="py-2 px-4 border-b border-gray-300">
                                            {credit.credits} Credits
                                        </td>
                                        <td class="py-2 px-4 border-b border-gray-300">
                                            ${credit.amount}
                                        </td>
                                        <td class="py-2 px-4 border-b border-gray-300">

                                        </td>
                                        <td class="py-2 px-4 border-b border-gray-300">
                                            <button
                                                className={`bg-${disableButton ? 'red-200' : 'primary'} text-white rounded-lg outline-none py-2 px-3 flex items-center w-[80%] gap-x-1 ${disableButton ? 'pointer-events-none' : 'hover:bg-red-500'}`}
                                                onClick={() => {
                                                    if (!disableButton) {
                                                        setDisableButton(true);
                                                        dispatch(handleOneTimePayment(credit.priceId));
                                                    }
                                                }}
                                                disabled={disableButton}
                                                style={{ justifyContent: 'center' }}
                                            >
                                                {/* <AiOutlinePlus />  */}
                                                <p>Buy</p>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>

                <button
                    className=" cursor-pointer px-8 py-2 mt-4 rounded-lg text-gray-500 view_btn border border-gray-400"
                    // className="flex items-center space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"

                    onClick={() => setShowCreditsModal(false)}
                >
                    Cancel
                </button>


            </Modal>

            {/* {
                addonConfirmation && (
                    <ConfirmationModal
                        heading="Upgrade Your Experience Instantly!"
                        message="End your free trial now and seamlessly transition into a subscription. Elevate your experience and enjoy additional credits instantly. It will cost you $100."
                        onCancel={() => { setAddonConfirmation(false) }}
                        onConfirm={() => { upgradeModalHandler() }}
                        loading={loading}
                    />
                )
            } */}
        </>
    )
}

export default CreditsModal;