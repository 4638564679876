import { Outlet, Navigate } from 'react-router-dom'
import { isExpired } from "react-jwt";
import { useSelector } from 'react-redux';
const PrivateRoutes = () => {
    const user = useSelector((state) => state.auth.user);
    
    let auth = localStorage.getItem('accessToken')
    const isMyTokenExpired = isExpired(auth);
    // console.log(isMyTokenExpired,"is")
    if(isMyTokenExpired) localStorage.clear();
    return(
        !isMyTokenExpired ? <Outlet/> : <Navigate to="/signin"/>
    )
}

export default PrivateRoutes