import React from "react";
import { connect } from "react-redux";

export const Input = (props) => {
  const {
    title,
    description,
    type,
    name,
    value,
    onChange,
    placeholder,
    onBlur,
    maxLength,
    children = null,
    required,
  } = props;

  const isMobileScreen = window.innerWidth < 768;
  return (
    <div className={`flex items-center ${isMobileScreen ? "w-full" : "w-3/4"}`}
      style={isMobileScreen ? {
        justifyContent: "space-around",
      } : {}}
    >      
    <div className="w-2/5 pt-3">
        <p className="text-Inter tracking-wide font-[500] text-sm leading-snug">
          {title}
          {required && <span className="text-red-500"> * </span>}
        </p>
        <label className="text-Inter text-gray-500 text-sm leading-snug">
          {description}
        </label>
      </div>
      <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
        {type === "textarea" ? (
          <textarea
            className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur}
            required
            rows={name === "outline" ? 8 : 3}
            maxLength={maxLength || 4000}
          />
        ) : (
          <input
            className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur}
            required
            maxLength={maxLength || 200}
          />
        )}
      </div>
      {children}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Input);
