import React from "react";

const Brief = ({ title, points }) => {
  return (
    <div className="px-6 py-2 flex flex-col gap-y-3 border border-gray-300 rounded-lg mt-3">
      <div className="w-fit flex flex-col gap-y-1">
        <h1 className="text-gray-700 font-semibold text-xl">{title}</h1>
        <p className="bg-primary h-1 rounded-lg w-3/4"></p>
      </div>
      <ul className="list-disc p-3">
        {points.map((p, i) => {
          return <li className="p-1 text-gray-600">{p}</li>;
        })}
      </ul>
    </div>
  );
};

export default Brief;
