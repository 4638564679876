import { BsFacebook } from "react-icons/bs";
import {
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { BiLogoTwitch, BiLogoTiktok } from "react-icons/bi";
import { FaDiscord } from "react-icons/fa";

const platforms = {
  facebook: <BsFacebook className="w-10 h-10 text-gray-600" />,
  twitter: <AiOutlineTwitter className="w-10 h-10 text-gray-600" />,
  twitch: <BiLogoTwitch className="w-10 h-10 text-gray-600" />,
  instagram: <AiOutlineInstagram className="w-10 h-10 text-gray-600" />,
  linkedin: <AiFillLinkedin className="w-10 h-10 text-gray-600" />,
  discord: <FaDiscord className="w-10 h-10 text-gray-600" />,
  tiktok: <BiLogoTiktok className="w-10 h-10 text-gray-600" />,
};
export default platforms;
