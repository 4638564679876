import React from "react";

const DeepunlockSkeleton = () => {
  return (
    <div className="flex flex-col gap-y-5 animate-pulse">
      <div className="flex flex-col gap-y-3">
        <div className="h-5 w-1/4 rounded-md bg-gray-200"></div>
        <div className="w-full flex items-center justify-between py-4">
          {[1, 2, 3].map((e, i) => {
            return (
              <div
                className="py-10 rounded-lg border bg-gray-200 flex flex-col w-[30%]"
                key={i}
              ></div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-y-3">
        <div className="h-5 w-1/4 rounded-md bg-gray-200"></div>
        <div className="w-full flex items-center justify-between py-4">
          {[1, 2, 3].map((e, i) => {
            return (
              <div
                className="py-10 rounded-lg border bg-gray-200 flex flex-col w-[30%]"
                key={i}
              ></div>
            );
          })}
        </div>
      </div>
      <div className="border border-gray-300 flex justify-around items-center p-4 rounded-lg">
        {[1, 2, 3, 4, 5, 6].map((e, i) => {
          return (
            <div
              key={i}
              className="text-center w-20 flex justify-center flex-col items-center gap-y-2"
            >
              <p className="subtitle w-full bg-gray-200 rounded-md h-3"></p>
              <h2 className="w-3/4 bg-gray-200 rounded-md h-4"></h2>
            </div>
          );
        })}
      </div>
      <div className="w-full flex flex-col gap-y-5 py-3">
        <div className="h-4 rounded-md bg-gray-200 w-[15%]"></div>
        <div className="flex gap-x-3 gap-y-3 items-center flex-wrap">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => {
            return (
              <div
                key={i}
                className="px-16 py-4 rounded-lg bg-gray-200 text-gray-600 h-3 w-fit"
              ></div>
            );
          })}
        </div>
      </div>
      <div className="border border-gray-300 flex justify-around items-center p-4 rounded-lg">
        {[1, 2, 3, 4, 5, 6].map((e, i) => {
          return (
            <div
              key={i}
              className="text-center w-20 flex justify-center flex-col items-center gap-y-2"
            >
              <p className="subtitle w-full bg-gray-200 rounded-md h-3"></p>
              <h2 className="w-3/4 bg-gray-200 rounded-md h-4"></h2>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeepunlockSkeleton;
