import React, { useRef, useState, useEffect } from "react";
import InfoHeader from "../../components/InfoHeader";
import TagSkeleton from "../../components/Skeleton/TagSkeleton";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { handleTagClick } from "../../helpers/utils";
import { GoCopy } from "react-icons/go";
import Tooltip from '@mui/material/Tooltip';

const HashtagGenerator = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    title: "",
    hashtags: [],
  });
  const [loading, setLoading] = useState(false);
  const creditsData = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsData?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);

  useEffect(() => {
    // Calculate the change in credits
    const creditChange = credit_balance - prevCreditsRef.current;

    // Display notification when credits_available changes
    if (creditChange !== 0) {
      const message = `${Math.abs(creditChange)} credits deducted for Hashtag generation`;
      if (creditChange < 0) {
        toast.success(message)
      }
    }

    // Update the ref to the current value for the next calculation
    prevCreditsRef.current = credit_balance;
  }, [credit_balance]);

  useEffect(() => {
    document.title = "Hashtag generator - YTA Studio";
  }, []);
  const getHashTags = (title) => {

    setLoading(true);
    authenticatedInstance
      .post(process.env.REACT_APP_BASE_URL + "/tag/hashtag-generator", {
        title: title,
        balanceReduction: 30,
        creditReduction: 3,
      })
      .then((res) => {

        if(res?.data?.status === false){
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }
        
        setData({
          title,
          hashtags: res?.data?.response?.hashtags,
        });
        dispatch({
          type: "UPDATE_STRIPE",
          payload: res.data?.stripeResult,
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error("Some error occured, please try again!");
        setLoading(false);
      });
  };
  return (
    <div className="p-3">
      <InfoHeader
        title={"Hashtag Generator"}
        subtitle={"Find hashtags to boost your video ranking."}
        buttonLabel={"Name"}
        placeholder={"Title for your video"}
        generateFunction={getHashTags}
        creditsRequired={process.env.REACT_APP_HASHTAG_GENERATOR_CREDITS}
        loading={loading}
        confirmationMessage={`This will generate hashtags for the given video Title. It will cost you ${process.env.REACT_APP_HASHTAG_GENERATOR_CREDITS} credits for each request.`}
        backButton={true}
        brief={true}
        briefTitle={"Why hashtag generator ?"}
        briefData={[
          "When you use relevant and popular hashtags, your content becomes more discoverable to a wider audience. ",
          "Hashtags can foster a sense of community and engagement among users with similar interests. ",
          "A hashtag generator simplifies the process of finding relevant and trending hashtags. It can provide you with hashtag suggestions based on your content, allowing you to save time and ensure you're using the most effective tags for your posts.",
          "Improved SEO for your video",
        ]}
      />
      {loading && <TagSkeleton />}
      {data.hashtags.length > 0 && (
        <div className="w-full border border-gray-200 rounded-lg p-6 flex flex-col gap-y-5">
          <div className="flex justify-between items-center">
            <h1 className="text-gray-700">
              Recommended hashtags for{" "}
              <span className="font-semibold">{data.title}</span>
            </h1>
            <button
              className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
              onClick={() => {
                const spaceSeparatedHashtags = data?.hashtags.join(" ");
                navigator.clipboard.writeText(spaceSeparatedHashtags);
                toast.success("All Hashtags Copied!");
              }}
            >
              <GoCopy /> <p>Copy All</p>
            </button>
          </div>

          <div className="flex gap-x-3 gap-y-3 items-center flex-wrap">
            {data?.hashtags.map((e, i) => {
              return (
                <Tooltip title="Click to copy" placement="top-end">
                  <p
                    key={i}
                    className="px-4 py-1 rounded-lg bg-gray-100 text-gray-600 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleTagClick(e, "Hashtag")}
                  >
                    {e}
                  </p>
                </Tooltip>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HashtagGenerator;
