import React, { useState, useEffect } from "react";
import { Modal } from "../components/Modal";

const PaymentStatus = () => {
    const clickHandler = () => {
        window.location.href = "/payment-options";
    }
    return (
        <div>
            <Modal to = "/signin">
                <h1>Payment Cancelled!</h1>
                <button onClick={clickHandler} className = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10" >OK</button>
            </Modal>
        </div>
    )
}

export default PaymentStatus;