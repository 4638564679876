import React from "react";
import { addCommasToNumber } from "../helpers/formatNumber";

const CardWithLogo = ({ logo, title, subtitle }) => {
  const isMobileScreen = window.innerWidth < 768;
  return (
    <div className="p-3 rounded-lg border border-gray-300 flex items-center space-x-3"
    style={isMobileScreen ? {width: "48%", marginBottom: "10px"} : {minWidth: "30%"}}
    >
      <p className="p-3 text-primary bg-red-50 rounded-full">{logo}</p>
      <div className="flex flex-col">
        <p className="subtitle">{title}</p>
        <p className="font-semibold text-gray-800">{addCommasToNumber(subtitle)}</p>
      </div>
    </div>
  );
};

export default CardWithLogo;
