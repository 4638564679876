import React from "react";
import incrementChart from "../assets/images/increment_chart.svg";
import decrementChart from "../assets/images/decrement_chart.svg";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { formatNumber } from "../helpers/formatNumber";

const GrowthCard = ({ title, value, increment }) => {
  return (
    <div className="p-3 rounded-lg border border-gray-300 flex w-[30%] items-center justify-evenly gap-x-3">
      <div className="flex flex-col space-y-3 w-[65%]">
        <p className="font-semibold subtitle text-sm">{title}</p>
        <h1 className="text-gray-800 font-bold title flex gap-x-2 items-center">
          {value < 0
            ? "-" + formatNumber(Math.abs(value))
            : formatNumber(value)}
          {increment ? (
            <AiOutlineArrowUp className="w-5 h-5 text-green-600" />
          ) : (
            <AiOutlineArrowDown className="w-5 h-5 text-red-600" />
          )}{" "}
        </h1>
      </div>
      <div className="">
        {increment ? (
          <img src={incrementChart} />
        ) : (
          <img src={decrementChart} />
        )}
      </div>
    </div>
  );
};

export default GrowthCard;
