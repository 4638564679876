import { FloatingMenu, BubbleMenu, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEditor } from "@tiptap/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditsData } from "../redux/actions/paymentActions";

import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineStrikethrough,
} from "react-icons/ai";

// define your extension array
const extensions = [StarterKit];

const Tiptap = ({ setEditorData }) => {
  const { scriptResult } = useSelector((state) => state.scriptForm);
  const { liveStreamData } = useSelector((state) => state.scriptForm);
  const dispatch = useDispatch();
  const editor = useEditor({
    extensions,
    content: liveStreamData || "",
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(liveStreamData.join(""));
      // editor.commands.setNode(liveStreamData)
      setEditorData(editor.getText());
    }
  }, [liveStreamData]);

  return (
    <>
      <EditorContent editor={editor} />
      <FloatingMenu editor={editor}>This is the floating menu</FloatingMenu>
      {editor && (
        <>
          <BubbleMenu editor={editor}>
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={`${editor.isActive("bold") ? "is-active" : null
                } mx-2 p-2 rounded-md`}
            >
              <AiOutlineBold />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={`${editor.isActive("italic") ? "is-active" : null
                } mx-2 p-2 rounded-md`}
            >
              <AiOutlineItalic />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={`${editor.isActive("strike") ? "is-active" : null
                } mx-2 p-2 rounded-md`}
            >
              <AiOutlineStrikethrough />
            </button>
          </BubbleMenu>
        </>
      )}

    </>
  );
};

export default Tiptap;
