import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Dashboard from "./ideaResearch/Dashboard";
import Sidebar from "../components/Sidebar";
import { useLocation } from "react-router-dom";
import Settings from "./Settings";
import VideoOptimize from "./videoOptimization/VideoOptimize";
import TitleGenerator from "./videoOptimization/TitleGenerator";
import TagGenerator from "./videoOptimization/TagGenerator";
import Keywordtool from "./videoOptimization/Keywordtool";
import HashtagGenerator from "./videoOptimization/HashtagGenerator";
import ScriptGenerator from "./scriptGenerator/ScriptGenerator";
import Support from "./Support";
import StartSearch from "./ideaResearch/StartSearch";
import CreateScript from "./scriptGenerator/CreateScript";
import ScriptResult from "./scriptGenerator/ScriptResult";
import YTAuditBasic from "./YTAudit/YTAuditBasic";
import TagExtractor from "./videoOptimization/TagExtractor";
import DescriptionGenerator from "./videoOptimization/DescriptionGenerator";
import ExpirePlanModal from "../components/ExpirePlanModal";
import { useNavigate } from "react-router-dom";
import GlobalCreditModal from "../components/GlobalCreditModal";
import { useSelector, useDispatch } from 'react-redux';
import PageNotFound from "./PageNotFound";
import BottomBar from "../components/BottomBar";

export const Index = (props) => {
  const { user } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);
  const [isPlanExpired, setIsPlanExpired] = useState(false);
  const showSubscriptionModal = useSelector((state) => state.stripe.showSubscriptionModal);

  useEffect(() => {
    if (user && user?.cancellationDate) {
      const expirationDate = new Date(user?.cancellationDate);

      const currentDate = new Date();
      if (expirationDate <= currentDate) {
        setIsPlanExpired(true);

        dispatch({
          type: "SUBSCRIPTION_MODAL",
          payload: true,
        })

      }
    }

  }, [user]);

  const creditsData = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsData?.credit_balance;

  const isMobileScreen = () => {
    return window.innerWidth < 768; // Adjust the breakpoint as needed
  };

  return (
    <div className="flex">

      {
        isMobileScreen() ? 
        // <div className="fixed bottom-0 w-full z-50">
          <BottomBar />
        // {/* </div> */}
          :
          <div
            id="sidebar"
            className="sm:visible transition-all duration-150 ease-in-out z-10"
          >
            <Sidebar
              setSidebarClosingStatus={setSidebarClosingStatus}
              sidebarClosingStatus={sidebarClosingStatus}
            />
          </div>
      }
      {/* <div className="flex-1"> */}
      <div className={`${(isMobileScreen() === false) && (sidebarClosingStatus ? 'ml-[240px] w-full' : 'ml-[80px] w-full')} z-1`}>
        {/* {currentRoute.includes('dashboard') && <Dashboard setSidebarClosingStatus={setSidebarClosingStatus} />} */}

        {location.pathname !== "/dashboard/settings" && (
          showSubscriptionModal && <GlobalCreditModal />
        )}

        {location.pathname === "/dashboard" && (
          <>
            <Dashboard
              setSidebarClosingStatus={setSidebarClosingStatus}
              sidebarClosingStatus={sidebarClosingStatus}
            />
          </>

        )}
        {location.pathname === "/dashboard/settings" && (
          <Settings setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/support" && (
          <Support setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname.startsWith("/dashboard/start-search") && (
          <StartSearch setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/aioptimization" && (
          <VideoOptimize setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/script-generator" && (
          <ScriptGenerator setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/create-script" && (
          <CreateScript setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname.startsWith("/dashboard/script-result") && (
          <ScriptResult setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/tag-generator" && (
          <TagGenerator setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/tag-extractor" && (
          <TagExtractor setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/title-generator" && (
          <TitleGenerator setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname === "/dashboard/description-generator" && (
          <DescriptionGenerator
            setSidebarClosingStatus={setSidebarClosingStatus}
          />
        )}

        {location.pathname === "/dashboard/hashtag-generator" && (
          <HashtagGenerator setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {location.pathname.startsWith("/dashboard/youtube-audit") && (
          <YTAuditBasic setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
        {
        isMobileScreen() && <div className="w-full h-[60px]"></div>
      }
      </div>
      {/* </div> */}
      {
        isPlanExpired && <ExpirePlanModal />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
