import React, { useState, useEffect } from "react";
import Header from "./Header";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsArrowLeft, BsArrowRight, BsRobot } from "react-icons/bs";
import { RiRobot2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { SelectOption } from "../../components/CustomSelection";
import { GeneralInfo, backgroundInfo } from "../../data/formData";
import { Input } from "../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import axios from "axios";
import { toast } from "sonner";
import { Modal } from "../../components/Modal";
import { PiPaperPlaneTiltBold } from "react-icons/pi";
import { format } from 'date-fns';
import ConfirmationModal from "../../components/ConfirmationModal";
import { createScriptRecord } from "../../redux/actions/scriptActions";

const CreateScript = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [selectedType, setSelectedType] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const currentPlan = useSelector((state) => state.stripe.currentPlan);
  const creditsData = useSelector((state) => state.stripe.creditsData);

  const credit_balance = creditsData?.credit_balance;
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [loading, setLoading] = useState(false);

  const videoTypes = [
    {
      title: "Video Tutorial",
      description: "Teach something useful or interesting.",
      popular: true,
    },
    {
      title: "Ask Me Anything (AMA) Video",
      description: "Answer fan questions live or recorded.",
      popular: false,
    },
    {
      title: "Whiteboard Video",
      description: "Explain a concept or idea visually.",
      popular: false,
    },
    {
      title: "Listicle Video",
      description: "Present a list of items or ideas.",
      popular: false,
    },
  ];

  const [formDetails, setFormDetails] = useState({
    video_length: "1-3 minutes",
    title: '',
    video_type: videoTypes[selectedType].title,
    video_topic: "",
    target_audience: "",
    outline: "",
    // conclusion: "",
    tone: "",
  });

  useEffect(() => {
    const now = new Date();
    const formattedDate = format(now, "do MMMM yyyy, hh:mm a");
    setCurrentDateTime(formattedDate);
    setFormDetails((prev) => {
      return {
        ...prev,
        title: formattedDate,
      };
    })
  }, []);

  const navigate = useNavigate();

  const onChange = (event) => {
    let { name, value } = event.target;
    if (name === "video_type") {
      value = videoTypes[selectedType].title
    }
    setFormDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const selectChange = (option) => {
    setFormDetails((prev) => {
      return {
        ...prev,
        video_length: option,
      };
    });
  };
  useEffect(() => {
    document.title = "Create a script - YTA Studio";
  }, []);
  const handleCancel = () => {
    navigate("/dashboard/script-generator");
  };
  const handleBack = () => {
    setStep(step - 1);
  };

  const isMobileScreen = window.innerWidth < 768;
  const handleNext = async () => {

    if (step === 1) {
      if (formDetails.title === "") {
        toast.error("title can't be empty!");
      } else {
        setStep(2);
      }
    } else {
      if (
        formDetails.video_length &&
        formDetails.title &&
        formDetails.target_audience &&
        formDetails.video_topic &&
        formDetails.video_type
      ) {
        let required_credits = 0;
        let videoLength = formDetails.video_length

        if (videoLength == '1-3 minutes') {
          required_credits = process.env.REACT_APP_SCRIPT_CREDITS1
        } else if (videoLength == '3-5 minutes') {
          required_credits = process.env.REACT_APP_SCRIPT_CREDITS2
        } else if (videoLength == '8-12 minutes') {
          required_credits = process.env.REACT_APP_SCRIPT_CREDITS3
        } else if (videoLength == '20+ minutes') {
          required_credits = process.env.REACT_APP_SCRIPT_CREDITS4
        }

        if (credit_balance < required_credits) {
          toast.error("You don't have enough credits to generate Script");
          return;
        }
        setShowModal(true);
      } else {
        toast.error("Please enter the required fields!");
      }
    }
  };

  const startSearch = async () => {

    const scriptId = await dispatch(createScriptRecord(formDetails))
    if (scriptId === false) {
      return;
    }

    const accessToken = localStorage.getItem("accessToken");
    await dispatch({
      type: "SET_FORM_DETAILS",
      payload: formDetails,
    });
    dispatch({
      type: "SCRIPT_LOADING",
      payload: true,
    });
    navigate(`/dashboard/script-result/${scriptId}`);
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/createScript",
        {
          method: "POST",
          body: JSON.stringify({
            id: scriptId,
            formDetails,
            balanceReduction: 30,
            creditReduction: 3,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.log(error.message);
      dispatch({
        type: "STREAM_ACTIVE",
        payload: false,
      });
    }
    // navigate("/dashboard/script-result");
  }

  return (
    <>
      {showModal && (

        <ConfirmationModal
          heading={"Credit Usage Confirmation"}
          message={`This will cost you approximately 
            ${formDetails.video_length === "1-3 minutes" ? process.env.REACT_APP_SCRIPT_CREDITS1 : formDetails.video_length === "3-5 minutes" ? process.env.REACT_APP_SCRIPT_CREDITS2 : formDetails.video_length === "8-12 minutes" ? process.env.REACT_APP_SCRIPT_CREDITS3 : process.env.REACT_APP_SCRIPT_CREDITS4}
          credits. Are you sure you want to continue?`}
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            dispatch({
              type: "SET_SCRIPT_RESULT",
              payload: {
                scriptId: null,
                data: "",
              }
            });
            setLoading(true)
            setShowModal(false);
            startSearch();
          }}

        />
      )}
      <div className={`${isMobileScreen ? "p-3" : "p-10"}`}>
        <Header step={step} />
        {step === 1 ? (
          <>
            <div className="flex flex-col space-y-2 my-4">
              <p className="subtitle">Name</p>
              <input
                className={`bg-[#F9FAFB] rounded-xl ${isMobileScreen ? "w-5/6" : "w-1/3"} placeholder-[#6B7280] text-gray-900 outline-none py-2 px-5 border border-[#E5E7EB]`}
                onChange={onChange}
                name="title"
                value={formDetails?.title}
                maxLength={200}
                autoFocus
              />

            </div>
            <div className="w-full flex flex-col space-y-3">
              <p className="subtitle">Type of video</p>
              {videoTypes.map(({ title, description, popular }, i) => {
                return (
                  <div
                    className={`flex items-center space-x-4 p-3 border rounded-lg w-full sm:w-[85%] cursor-pointer ${selectedType === i ? "border-primary" : "border-[#E4E4E7]"
                      } `}
                    onClick={() => {
                      setFormDetails({
                        ...formDetails,
                        video_type: videoTypes[i].title,
                      });
                      setSelectedType(i);
                    }}
                    key={i}
                  >
                    {selectedType === i ? (
                      <AiOutlineCheckCircle className="h-6 w-6 text-primary" />
                    ) : (
                      <div
                        className={`h-6 w-6 rounded-full border border-[#E4E4E7] flex items-center justify-center`}
                      ></div>
                    )}
                    <div className="flex flex-col space-y-1">
                      <div className="text-lg flex">
                        <p className="text-[#434b57] font-semibold">{title}</p>
                        <span
                          className={`${popular ? "inline" : "hidden"
                            } bg-[#FECDCA] text-primary py-1 px-2 rounded-full text-sm ml-2`}
                          style={{
                            height: "fit-content",
                          }}
                        >
                          Popular
                        </span>
                      </div>
                      <p className="subtitle">{description}</p>
                    </div>
                  </div>
                )
              })}
            </div>

          </>
        ) : (
          <div className="flex flex-col space-y-6 my-5">
            <div className="flex flex-col space-y-5">
              <h1 className="font-semibold">
                1. Select the length of your video
              </h1>
              <SelectOption
                title={"Select video length"}
                description="A common video length for a youtube video is 5 minutes"
                options={[
                  "1-3 minutes",
                  "3-5 minutes",
                  "8-12 minutes",
                ]}
                onChange={selectChange}
                name="video_length"
                value={formDetails.video_length}
              />
            </div>
            <hr className="bg-gray-200 h-[1.15px] mt-1" />
            <div className="flex flex-col space-y-5">
              <h1 className="font-semibold">2. Enter General Info About Video</h1>
              {GeneralInfo.map(
                (
                  { title, description, placeholder, name, maxLength, required },
                  i
                ) => {
                  return (
                    <Input
                      title={title}
                      description={description}
                      placeholder={placeholder}
                      key={i}
                      type="text"
                      name={name}
                      onChange={onChange}
                      value={formDetails[name]}
                      maxLength={maxLength}
                      required={required}
                    />
                  );
                }
              )}
            </div>
            <hr className="bg-gray-200 h-[1.15px] mt-1" />
            <div className="flex flex-col space-y-5">
              <h1 className="font-semibold">3. Background Info about Video</h1>
              {backgroundInfo.map(
                (
                  { title, description, placeholder, name, maxLength, required },
                  i
                ) => {
                  return (
                    <Input
                      title={title}
                      description={description}
                      placeholder={placeholder}
                      key={i}
                      type="textarea"
                      onChange={onChange}
                      name={name}
                      value={formDetails[name]}
                      maxLength={maxLength}
                      required={required}
                    />
                  );
                }
              )}
            </div>
          </div>
        )}
        <div className="flex items-center justify-between my-5">
          <button
            className="font-semibold border border-[#E4E4E7] rounded-lg px-5 py-2"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <div className="w-fit flex items-center space-x-4">
            {step === 2 && (isMobileScreen === false) && (
              <button
                className="font-semibold border border-[#E4E4E7] rounded-lg px-5 py-2 flex items-center gap-x-2"
                onClick={handleBack}
              >
                <BsArrowLeft />
                <p>Back</p>
              </button>
            )}
            <button
              className="flex items-center space-x-2 bg-primary text-white px-5 py-2 rounded-lg"
              onClick={handleNext}
            >


              {
                step === 1 ? (
                  <>
                    <BsArrowRight />
                    <p>Next</p>
                  </>
                ) : (
                  <>
                    {loading ? (
                      <>
                        <div className="flex items-center gap-x-2">
                          <div className="animate-spin h-5 w-5 border-t-2 border-b-2 border-white rounded-full"></div>
                          <p>Loading...</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <RiRobot2Line />
                        <p>Generate</p>
                      </>
                    )}
                  </>
                )
              }
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateScript;
