import { Toaster } from "sonner";
import { useState, useEffect } from "react";
import PrivateRoute from "./components/PrivateRoute";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Authentication from "./pages/Authentication/Authentication.js";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";
import PaymentOptions from "./pages/PaymentOptions/PaymentOptions";
import PaymentStatus from "./pages/PaymentStatus";
import Index from "./pages/Index";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserFirst } from "./redux/actions/auth";
import { usePostHog } from 'posthog-js/react'
import { creditsData } from "./redux/actions/paymentActions.js";
import PageNotFound from "./pages/PageNotFound";
import PrivatePaymentRoute from "./components/PrivatePaymentRoute.js";

function App() {
  // const { loading } = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const user = useSelector((state) => state.auth.user);
  const accessToken = localStorage.getItem('accessToken');
  useEffect(() => {
    if (accessToken) {
      dispatch({ type: 'SET_LOADING', payload: true });
      dispatch(fetchUserFirst())
        .then(() => {
          dispatch(creditsData());
          dispatch({ type: 'SET_LOADING', payload: false });
        })
        .catch((error) => {
          dispatch({ type: 'SET_LOADING', payload: false });
        });
    }
  }, [accessToken, dispatch, navigate]);

  // console.log(user)

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you may want to limit how often you call it
      posthog?.identify(user?.name, {
        email: user?.email,
      })
    }
  }, [posthog, user])

  return (
    <>
      <Toaster position="top-right" richColors closeButton />
      <Routes>
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/signin" element={<Authentication />} />
        <Route path="/backdoor" element={<Authentication />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/payment-options" element={<PaymentOptions />} />
        {/* <Route path="/payment-success" element={<PaymentStatus />} /> */}
        <Route path="/payment-failed" element={<PaymentStatus />} />
        <Route element={<PrivateRoute />}>
          <Route element={<PrivatePaymentRoute />} >
            <Route path="/dashboard/" element={<Index />} />

            <Route path="/dashboard/aioptimization" element={<Index />} />
            <Route path="/dashboard/title-generator" element={<Index />} />
            <Route path="/dashboard/tag-extractor" element={<Index />} />
            <Route path="/dashboard/description-generator" element={<Index />} />
            <Route path="/dashboard/keyword-research" element={<Index />} />
            <Route path="/dashboard/hashtag-generator" element={<Index />} />
            <Route path="/dashboard/tag-generator" element={<Index />} />

            <Route path="/dashboard/start-search" element={<Index />} />
            <Route path="/dashboard/start-search/:id" element={<Index />} />
            <Route path="/dashboard/youtube-audit" element={<Index />} />
            <Route path="/dashboard/youtube-audit/*" element={<Index />} />

            <Route path="/dashboard/create-script" element={<Index />} />
            <Route path="/dashboard/script-generator" element={<Index />} />
            <Route path="/dashboard/script-result/*" element={<Index />} />

            <Route path="/dashboard/support" element={<Index />} />
            <Route path="/dashboard/settings" element={<Index />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
        {/* <Route path="/dashboard/*" element={<Index />} /> */}
      </Routes>
    </>
  );
}

export default App;
