const initState = {
  loading: false,
  formDetails: {
    title: "",
    video_type: "",
    video_topic: "",
    target_audience: "",
    problem: "",
    desired_result: "",
    // conclusion: "",
    tone: "",
  },
  scriptId: null,
  scriptResult: "",
  generatedScriptLoading: true,
  streamActive: false,
  liveStreamData: [],
  scriptTitle: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SCRIPT_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_LIVE_STREAM_DATA':
      return {
        ...state,
        liveStreamData: action.payload.data,
        scriptTitle: action.payload.title
      }
    case "SET_FORM_DETAILS":
      return {
        ...state,
        formDetails: action.payload,
      };
    case "SET_SCRIPT_RESULT":
      return {
        ...state,
        scriptId: action.payload?.scriptId,
        scriptResult: action.payload?.data,
      };
    case "UPDATE_SCRIPT_RESULT":
      return {
        ...state,
        scriptId: action.payload?.scriptId,
        scriptResult: state.scriptResult + action.payload?.data,
      };
    case "RESET_SCRIPT_STATE":
      return {
        ...state,
        loading: false,
        formDetails: {
          title: "",
          video_type: "",
          video_topic: "",
          target_audience: "",
          problem: "",
          desired_result: "",
          // conclusion: "",
          tone: "",
        },
        scriptResult: "",
        scriptId: null,
      };
    case "GENERATED_SCRIPT_LOADING":
      return {
        ...state,
        generatedScriptLoading: action.payload,
      };
    case "STREAM_ACTIVE":
      return {
        ...state,
        streamActive: action.payload,
      };
    default:
      return state;
  }
};
