import React, { useRef, useState, useEffect } from "react";
import InfoHeader from "../../components/InfoHeader";
import { GeneralInfo, backgroundInfo } from "../../data/descriptionData";
import { SelectOption } from "../../components/CustomSelection";
import { Input } from "../../components/CustomInput";
import { Link } from "react-router-dom";
import { PiPaperPlaneTiltBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { toast } from "sonner";
import { Modal } from "../../components/Modal";
import { IoCreateSharp } from "react-icons/io5";
import ConfirmationModal from "../../components/ConfirmationModal";
import { handleTagClick } from "../../helpers/utils";
import Tooltip from '@mui/material/Tooltip';

const DescriptionGenerator = () => {
  const [formDetails, setFormDetails] = useState({
    title: "",
    video_type: "",
    video_topic: "",
    target_audience: "",
    keywords: "",
    conclusion: "",
    angle: "",
  });
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const creditsData = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsData?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);

  useEffect(() => {
    // Calculate the change in credits
    const creditChange = credit_balance - prevCreditsRef.current;

    // Display notification when credits_available changes
    if (creditChange !== 0) {
      const message = `${Math.abs(creditChange)} credits deducted for description generation`;
      if (creditChange < 0) {
        toast.success(message)
      }
    }

    // Update the ref to the current value for the next calculation
    prevCreditsRef.current = credit_balance;
  }, [credit_balance]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const selectChange = (option) => {
    setFormDetails((prev) => {
      return {
        ...prev,
        video_type: option,
      };
    });
  };

  useEffect(() => {
    document.title = "Description generator - YTA Studio";
  }, []);

  const generateDescription = async () => {
    if (
      formDetails.title &&
      formDetails.video_type &&
      formDetails.video_topic &&
      formDetails.target_audience
    ) {


      if (creditsData?.credit_balance < process.env.REACT_APP_DESCRIPTION_GENERATOR_CREDITS) {
        toast.error("Insufficient credits to perform this action");
        return;
      }

      setLoading(true);
      authenticatedInstance
        .post(process.env.REACT_APP_BASE_URL + "/description-generator", {
          formDetails,
        })
        .then((res) => {

          if(res?.data?.status === false){
            toast.error(res?.data?.message);
            setLoading(false);
            return;
          }

          setData(res?.data?.data[0]?.message.content);
          dispatch({
            type: "UPDATE_STRIPE",
            payload: res?.data?.stripeResult,
          });
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
            toast.error("Some error occured, please try again!");
            setLoading(false);
        });
    } else {
      toast.error("Please enter the required fields!");
    }
  };
  return (
    <>
      {showModal && (
        <ConfirmationModal
          heading={"Credit Usage Confirmation"}
          message={`This will cost you approximately ${process.env.REACT_APP_DESCRIPTION_GENERATOR_CREDITS} credits. Are you sure you want to continue?`}
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            generateDescription();
            setShowModal(false);
          }}
        />
        // <Modal handleClose={() => setShowModal(false)}>
        //   <div className="flex flex-col items-center justify-center">
        //     <h1 className="title">Are you sure?</h1>
        //     <p className="text-gray-500 text-center">
        //       This will generate a relevant description for the information provided. It will cost you {process.env.REACT_APP_DESCRIPTION_GENERATOR_CREDITS} credits for each request.
        //     </p>
        //     <div className="flex space-x-2 mt-5">
        //       <button
        //         className="flex items-center space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"
        //         onClick={() => setShowModal(false)}
        //       >
        //         <p>Cancel</p>
        //       </button>
        //       <button
        //         className="flex items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
        //         onClick={() => {
        //           setShowModal(false);
        //           generateDescription();
        //         }}
        //       >
        //         <IoCreateSharp />
        //         <p>Generate 
        //           {/* ({process.env.REACT_APP_DESCRIPTION_GENERATOR_CREDITS} Credits) */}
        //           </p>
        //       </button>
        //     </div>
        //   </div>

        // </Modal>
      )}
      <div className="p-3">
        <InfoHeader
          title={"Description Generator"}
          subtitle={"Generate description for your youtube video"}
          backButton={true}
        />
        <div className="flex flex-col space-y-6 my-5 p-6">
          <div className="flex flex-col space-y-5">
            <h1 className="font-semibold">1. Select the video type</h1>
            <SelectOption
              title={"Select the video type"}
              description="Select Video type"
              options={[
                "Video Tutorial",
                "Ask Me Anything (AMA) Video",
                "Whiteboard Video",
                "Listicle Video",
              ]}
              onChange={selectChange}
              name="video_type"
              value={formDetails.video_type}
            />
          </div>
          <hr className="bg-gray-200 h-[1.15px] mt-1" />
          <div className="flex flex-col space-y-5">
            <h1 className="font-semibold">2. Enter General Info About Video</h1>
            {GeneralInfo.map(
              (
                { title, description, placeholder, name, maxLength, required },
                i
              ) => {
                return (
                  <Input
                    title={title}
                    description={description}
                    placeholder={placeholder}
                    key={i}
                    type="text"
                    name={name}
                    onChange={onChange}
                    value={formDetails[name]}
                    maxLength={maxLength}
                    required={required}
                  />
                );
              }
            )}
          </div>
          <hr className="bg-gray-200 h-[1.15px] mt-1" />
          <div className="flex flex-col space-y-5">
            <h1 className="font-semibold">3. Background About Video</h1>
            {backgroundInfo.map(
              (
                { title, description, placeholder, name, maxLength, required },
                i
              ) => {
                return (
                  <Input
                    title={title}
                    description={description}
                    placeholder={placeholder}
                    key={i}
                    type="textarea"
                    onChange={onChange}
                    name={name}
                    value={formDetails[name]}
                    maxLength={maxLength}
                    required={required}
                  />
                );
              }
            )}
          </div>
          {!data && (
            <div className="w-fit flex items-center space-x-4 ml-auto pr-10 my-5 pt-5">
              <Link className="font-semibold border border-gray-300 rounded-lg px-5 py-2">
                Cancel
              </Link>
              <button
                className="flex items-center space-x-2 bg-primary text-white px-5 py-2 rounded-lg"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {loading ? (
                  <>
                    <div className="flex items-center gap-x-2">
                      <div className="animate-spin h-5 w-5 border-t-2 border-b-2 border-white rounded-full"></div>
                      <p>Loading...</p>
                    </div>
                  </>
                ) : (
                  <>
                    <IoCreateSharp />
                    <p>Generate
                      {/* ({process.env.REACT_APP_DESCRIPTION_GENERATOR_CREDITS} Credits) */}
                    </p>
                  </>
                )}
              </button>
            </div>
          )}
        </div>
        {loading && (
          <div className="p-5 border border-gray-300 rounded-lg flex flex-col gap-y-3 animate-pulse">
            <h2 className="h-5 rounded-md bg-gray-200 w-1/3"></h2>
            <div className="flex flex-col gap-y-2">
              {[1, 2, 3, 4, 5, 6].map((e, i) => {
                return (
                  <p
                    className={`${e === 1 || e === 3 ? "w-3/4" : "w-1/2"
                      } bg-gray-200 rounded-md h-3`}
                    key={i}
                  ></p>
                );
              })}
            </div>
          </div>
        )}
        {data && (
          <div className="p-5 border border-gray-300 rounded-lg flex flex-col gap-y-3">
            <h2 className="text-xl text-gray-800 font-semibold capitalize">
              {formDetails.title}
            </h2>
            <Tooltip title="Click to copy" placement="top-end">
              <div
                className="flex flex-col gap-y-1 text-gray-600 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  const paragraphContent = data.split("\n").join(" ");
                  handleTagClick(paragraphContent, "Description");
                }}
              >
                {data.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </Tooltip>
          </div>
        )}

      </div>
    </>
  );
};

export default DescriptionGenerator;
