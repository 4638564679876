import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const getSearchResult = (id) => async (dispatch) => {
    try {
        const { data } = await authenticatedInstance.get('/search/getSearchResult', {
            params: { id },
        });

        dispatch({
            type: "GET_SEARCH_RESULT",
            payload: {
                searchId: data?.searchId,
                data: data?.result,
                searchTerm : data?.searchTerm
            }
        });

        dispatch({
            type: "GET_RESULT_LOADING",
            payload: false,
        });
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};

export const searchMore = (id) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.get('/search/searchMore', {
            params: { id },
        });

        if (status === 200) {
            dispatch({
                type: "UPDATE_STRIPE",
                payload: data?.stripeResult,
            });

            dispatch({
                type: "GET_SEARCH_RESULT",
                payload: {
                    searchId: data?.searchId,
                    data: data?.result,
                    searchTerm : data?.searchTerm
                }
            });


            return true;
        }
    } catch (err) {
        console.error(err);
        toast.error("Something went wrong!")
        return false;
    }
}

export const deleteCopy = (id) => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.delete('/search/deleteCopy', {
            data: {
                id
            }
        })
        if (status) {
            toast.success("Copy deleted successfully!")
        }
        console.log("deleted")
        if (status === 200) {
            dispatch({
                type: "GET_GENERATED_DATA",
                payload: data?.data,
            });
            return true
        }

    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Something went wrong!")
        return false
    }

}