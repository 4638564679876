const Ranks = [
  {
    title: "Social Blade Rank",
    value: "sbrank",
  },
  {
    title: "Subscribers Rank",
    value: "subscribers",
  },
  {
    title: "Views Rank",
    value: "views",
  },
  {
    title: "Country Rank",
    value: "country",
  },
  {
    title: "Tech Rank",
    value: "channel_type",
  },
];
export default Ranks;
