import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { BiSearch } from "react-icons/bi";
import { Modal } from "../../components/Modal";
import { deleteCopy, getSearchResult } from "../../redux/actions/ideaActions";
import { formatDate } from "../../helpers/utils";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";

export const SearchData = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCopy, setSelectedCopy] = useState(null);
  const [openedDropdown, setOpenedDropdown] = useState(null);
  const generatedDataLoading = useSelector((state) => state.ideas.generatedDataLoading);
  const inventoryData = props.tableData;

  const getInventoryData = async () => {
    dispatch({
      type: "SCRIPT_LOADING",
      payload: true,
    });
    await authenticatedInstance
      .get(process.env.REACT_APP_BASE_URL + "/search/getGeneratedIdeas", {})
      .then((res) => {
        dispatch({
          type: "SCRIPT_LOADING",
          payload: false,
        });
        dispatch({
          type: "GET_GENERATED_DATA",
          payload: res.data,
        });
        dispatch({
          type: "GENERATED_DATA_LOADING",
          payload: false,
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const customNoDataOverlay = () => {
    return (
      <div className="w-full py-4 flex flex-col items-center justify-center space-y-3 h-full">
        {generatedDataLoading ? (
          <p className="text-lg font-semibold text-gray-700">
            <div class="loader ease-linear rounded-full border-4 border-t-6 border-gray-300 h-12 w-12"></div>
          </p>
        ) : (
          <>
            <BiSearch className="w-10 h-10 text-gray-400" />
            <p className="text-lg font-semibold text-gray-700">
              There is no data to show
            </p>
            <p>Click on the start search button to start your search</p>
          </>
        )}
      </div>
    );
  };


  const deleteModalHandler = (id) => {
    setSelectedCopy(id);
    setShowDeleteModal(!showDeleteModal);

    if (!id) {
      // Close the modal and set openedDropdown to null
      setShowDeleteModal(false);
      setOpenedDropdown(null);
    }
  }


  const deleteCopyHandler = async (id) => {
    // Perform the delete operation here, if needed
    dispatch(deleteCopy(id))

    // Close the modal and set openedDropdown to null
    setShowDeleteModal(false);
    setOpenedDropdown(null);
  }


  useEffect(() => {
    dispatch({
      type: "GET_SEARCH_RESULT",
      payload: {
        searchId: null,
        searchTerm: "",
        data: [],
      }
    });
    getInventoryData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);



  // Function to toggle the dropdown
  const toggleDropdown = (id) => {
    if (openedDropdown === id) {
      setOpenedDropdown(null);
    } else {
      setOpenedDropdown(id);
    }
  };


  const dispatchSearchResult = (id) => {
    navigate(`/dashboard/start-search/${id}`);
  };

  const columns = [
    {
      field: "searchTerm",
      headerName: "Name",
      flex: 2,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer"
            onClick={() => dispatchSearchResult(params.row?.id)}
          >
            <div className="font-bold text-[13.5px] text-[#000000de] truncate capitalize">
              {params.row?.searchTerm}
            </div>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3"
            onClick={() => dispatchSearchResult(params.row?.id)}
          >
            {formatDate(params.row?.createdAt)}
          </div>
        );
      },
    },
    // {
    //   field: "Results",
    //   headerName: "Results",
    //   flex: 2,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         className="flex items-center w-[100%] h-full px-3 cursor-pointer"
    //         onClick={() => dispatchSearchResult(params.row?.id)}
    //       >
    //         <div className="font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3">
    //           {params.row?.result && params.row?.result.length}
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      field: " ",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="w-3/4 flex"
          style={{
            marginLeft: "auto",
          }}>
            <svg
              onClick={() => toggleDropdown(params.row?.id)}
              className='w-8 py-1.5 mr-7 hover:bg-slate-200 px-1.5 mt-2 rounded-lg hover:cursor-pointer'
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
            </svg>
            {openedDropdown === params.row?.id && (
              <ul className="origin-top-right absolute  ml-2 mt-10 w-32 rounded-md z-40 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className='p-1'>
                  <div className='cursor-pointer'>
                    <button
                      id={'tags'}
                      onClick={() => deleteModalHandler(params.row?.id)}
                      className="hover:bg-slate-100 hover:text-slate-900 text-slate-700 group flex rounded-md items-center w-full px-2 py-2 text-sm">
                      <svg className="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                      Delete
                    </button>
                  </div>
                </div>
              </ul>
            )}
          </div>
        );
      },
    },
  ];

  const isMobileScreen = window.innerWidth < 768;

  return (
    <>
      {showDeleteModal && (
        <Modal width={'w-1/4'} handleClose={() => deleteModalHandler(null)}>
          <div className="flex flex-col space-y-3">
            <svg className='mx-auto' width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7617_1695)">
                <mask id="mask0_7617_1695" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                  <path d="M56 0H0V56H56V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_7617_1695)">
                  <path d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z" fill="#FFD7D5" />
                  <path d="M23 19V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H31.4C31.9601 24 32.2401 24 32.454 23.891C32.6422 23.7951 32.7951 23.6422 32.891 23.454C33 23.2401 33 22.9601 33 22.4V20M33 37V30.6C33 30.0399 33 29.7599 32.891 29.546C32.7951 29.3578 32.6422 29.2049 32.454 29.109C32.2401 29 31.9601 29 31.4 29H24.6C24.0399 29 23.7599 29 23.546 29.109C23.3578 29.2049 23.2049 29.3578 23.109 29.546C23 29.7599 23 30.0399 23 30.6V37M37 25.3255V32.2C37 33.8802 37 34.7202 36.673 35.362C36.3854 35.9265 35.9265 36.3854 35.362 36.673C34.7202 37 33.8802 37 32.2 37H23.8C22.1198 37 21.2798 37 20.638 36.673C20.0735 36.3854 19.6146 35.9265 19.327 35.362C19 34.7202 19 33.8802 19 32.2V23.8C19 22.1198 19 21.2798 19.327 20.638C19.6146 20.0735 20.0735 19.6146 20.638 19.327C21.2798 19 22.1198 19 23.8 19H30.6745C31.1637 19 31.4083 19 31.6385 19.0553C31.8425 19.1043 32.0376 19.1851 32.2166 19.2947C32.4184 19.4184 32.5914 19.5914 32.9373 19.9373L36.0627 23.0627C36.4086 23.4086 36.5816 23.5816 36.7053 23.7834C36.8149 23.9624 36.8957 24.1575 36.9447 24.3615C37 24.5917 37 24.8363 37 25.3255Z" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z" stroke="#FEF2F2" stroke-width="8" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_7617_1695">
                  <rect width="56" height="56" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {/* <h1 className="title">Are you sure you want to delete the copy?</h1> */}

            <p className="text-lg text-slate-600 text-center font-semibold text-Inter mt-3">
              Are you sure?
            </p>
            <p className="text-sm text-secondarySupport mt-1.5 text-Inter text-center">
              Are you sure you want to delete the copy? This action cannot be undone.
            </p>

            {/* <p className="subtitle text-sm"></p> */}
            <div className="flex items-center ml-auto text-sm pt-1 font-medium space-x-3">
              <button
                className="flex cursor-pointer items-csenter space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"
                onClick={() => deleteModalHandler(null)}
              >
                <p>Cancel</p>
              </button>
              <button
                className="flex cursor-pointer items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
                onClick={() => {
                  deleteCopyHandler(selectedCopy);
                }}
              >
                <p>Delete</p>
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div className={`fade-in font-Inter`}>
        <div className={`w-full pb-1.5 ${isMobileScreen ? "border-1px border-[#EAECF0]" : ""}`}
        style={isMobileScreen ? {
          width: "100vw",
          overflowX: "scroll",
        }: {}}>
          <div className={`${isMobileScreen ? "h-40rem]" : "h-[42rem]"}`}
          style={isMobileScreen ? {
            width: "100vw",
          } : { width: "100%" }}>
            {/* <div className="min-h-[30rem]"> */}
            <DataGrid
              sx={{
                overflowX: "scroll",
                border: 0,
                "& .css-17jjc08-MuiDataGrid-footerContainer": {
                  borderTop: 0,
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "700",
                  fontSize: "13px",
                  lineHeight: "18px",
                  fontFamily: "Inter",
                },
                "& MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F2F5F7",
                },
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-selectedRowCount": {
                  visibility: "hidden",
                },
                "& .css-17jjc08-MuiDataGrid-footerContainer": {
                  borderTop: 0,
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1.15rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  paddingLeft: "10px",
                  backgroundColor: "#F2F5F7",
                },
                "& .MuiDataGrid-cell": {
                  padding: "0px",
                  minWidth: "300px",
                },
                "& .MuiDataGrid-colCell": {
                  minWidth: "160px",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "#F3F4F6",
                },
                "& .MuiDataGrid-row.Mui-selected:hover": {
                  backgroundColor: "#F3F4F6",
                },
                overflowX: isMobileScreen ? 'auto' : 'scroll',
                whiteSpace: 'nowrap', // Prevent text wrapping
              }}
              rows={inventoryData}
              columns={columns}
              loading={loading}
              getRowId={(row) => row?.id || Math.random()}
              slots={{
                noRowsOverlay: customNoDataOverlay,
                noResultsOverlay: customNoDataOverlay,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'Name', sort: 'desc' }],
                  sortModel: [{ field: 'Date', sort: 'desc' }],
                },
                pagination: { paginationModel: { pageSize: 20 } },
              }}
              pageSizeOptions={[20, 30, 50]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
