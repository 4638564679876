import React from "react";

const BasicAuditSkeleton = () => {
  return (
    <div className="p-5 flex flex-col space-y-6 animate-pulse">
      <div className="w-full flex items-center">
        <img className="h-[15rem] w-[25rem] rounded-lg bg-gray-300" />
        <div className="flex flex-col space-y-3 w-1/2 px-5">
          <div className="flex flex-col space-y-1 w-full">
            <div className="h-5 w-3/4 rounded-md bg-gray-200"></div>
            <div className="h-3 w-1/4 rounded-md bg-gray-200"></div>
          </div>
          <div className="flex flex-col space-y-2 w-full">
            <div className="h-3 w-[60%] rounded-md bg-gray-200"></div>
            <div className="h-3 w-1/2 rounded-md bg-gray-200"></div>
            <div className="h-3 w-1/2 rounded-md bg-gray-200"></div>
          </div>
        </div>
      </div>
      <div className="border border-gray-300 flex justify-around items-center p-4 rounded-lg">
        {[1, 2, 3, 4, 5, 6].map((e, i) => {
          return (
            <div
              key={i}
              className="text-center w-20 flex justify-center flex-col items-center gap-y-2"
            >
              <p className="subtitle w-full bg-gray-200 rounded-md h-3"></p>
              <h2 className="w-3/4 bg-gray-200 rounded-md h-4"></h2>
            </div>
          );
        })}
      </div>
      <div className="w-full">
        <div className="h-5 w-1/4 rounded-md bg-gray-200"></div>
        <div className="w-full flex items-center justify-between py-4">
          {[1, 2, 3].map((e, i) => {
            return (
              <div className="p-3 rounded-lg border border-gray-300 flex flex-col w-[30%]">
                <div className="flex w-full">
                  <div className="h-4 w-1/2 rounded-md bg-gray-200"></div>
                  <div className="ml-auto rounded-full p-4 bg-gray-200"></div>
                </div>
                <div className="flex flex-col gap-y-2">
                  <div className="h-2 w-3/4 rounded-md bg-gray-200"></div>
                  <div className="h-2 w-1/2 rounded-md bg-gray-200"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full">
        <div className="h-5 w-1/4 rounded-md bg-gray-200"></div>
        <div className="w-full flex items-center justify-between py-4">
          {[1, 2, 3].map((e, i) => {
            return (
              <div className="p-3 rounded-lg border border-gray-300 flex flex-col w-[30%]">
                <div className="flex w-full">
                  <div className="h-4 w-1/2 rounded-md bg-gray-200"></div>
                  <div className="ml-auto rounded-full p-4 bg-gray-200"></div>
                </div>
                <div className="flex flex-col gap-y-2">
                  <div className="h-2 w-3/4 rounded-md bg-gray-200"></div>
                  <div className="h-2 w-1/2 rounded-md bg-gray-200"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BasicAuditSkeleton;
