import React, { useRef, useState, useEffect } from "react";
import InfoHeader from "../../components/InfoHeader";
import TitleSkeleton from "../../components/Skeleton/TitleSkeleton";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { handleTagClick } from "../../helpers/utils";
import Tooltip from '@mui/material/Tooltip';

const TitleGenerator = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    description: "",
    titles: [],
  });
  const [loading, setLoading] = useState(false);
  const creditsData = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsData?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);

  useEffect(() => {
    // Calculate the change in credits
    const creditChange = credit_balance - prevCreditsRef.current;

    // Display notification when credits_available changes
    if (creditChange !== 0) {
      const message = `${Math.abs(creditChange)} credits deducted for Title Generation`;
      if (creditChange < 0) {
        toast.success(message)
      }
    }

    // Update the ref to the current value for the next calculation
    prevCreditsRef.current = credit_balance;
  }, [credit_balance]);

  useEffect(() => {
    document.title = "Title generator - YTA Studio";
  }, []);

  const getTitles = (description) => {

    setLoading(true);
    authenticatedInstance
      .post(process.env.REACT_APP_BASE_URL + "/title", {
        description: description,
        balanceReduction: 30,
        creditReduction: 3,
      })
      .then((res) => {

        if (res?.data?.status === false) {
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }

        setData({
          description,
          titles: res?.data?.response?.titles,
        });
        dispatch({
          type: "UPDATE_STRIPE",
          payload: res.data?.stripeResult,
        });
        setLoading(false);
      })
      .catch((e) => {
        // if status code 401, then insufficient credits

        console.log(e);
        toast.error("Some error occured, please try again!");
        setLoading(false);
      });
  };

  return (
    <div className="p-3">
      <InfoHeader
        title={"Title Generator"}
        subtitle={"Make titles that will grab attention."}
        buttonLabel={"About"}
        placeholder={"What is your video about?"}
        generateFunction={getTitles}
        creditsRequired={process.env.REACT_APP_TITLE_GENERATOR_CREDITS}
        loading={loading}
        confirmationMessage={"This will generate titles for the given video description. It will cost you " + process.env.REACT_APP_TITLE_GENERATOR_CREDITS + " credits for each request."}
        backButton={true}
        brief={true}
        briefTitle={"Why title generator ?"}
        briefData={[
          "A well-crafted title is the first thing viewers see, and it's essential for capturing their attention. An engaging title piques curiosity and encourages users to click on your video.",
          "Increase the click rate for your video",
          "Improve searchability and SEO for your video",
          "A consistent and well-branded title style helps establish your channel's identity. Viewers can easily recognize your content, which can lead to higher subscriber retention.",
        ]}
      />
      {loading && <TitleSkeleton />}
      {data?.titles?.length > 0 && (
        <div className="w-full border border-gray-200 rounded-lg p-6 flex flex-col gap-y-5">
          <h1 className=" text-gray-700 font-semibold">
            Recommended titles for given description
          </h1>
          <div className="flex gap-y-3 flex-wrap flex-col">
            {data?.titles?.map((e, i) => {
              return (
                <Tooltip title="Click to copy" placement="top-end">
                  <p
                    key={i}
                    className="px-4 py-1 rounded-lg bg-gray-100 text-gray-600 w-fit cursor-pointer hover:bg-gray-200"
                    onClick={() => handleTagClick(e, "Title")}
                  >
                    {e}
                  </p>
                </Tooltip>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TitleGenerator;
