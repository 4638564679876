import { Card, Title, AreaChart } from "@tremor/react";

const CustomAreaChart = ({ title, data, urls, category }) => {
  const valueFormatter = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + "B";
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "k";
    } else {
      return num?.toString();
    }
  };

  let dataWithUrls = data;

  // merge data and urls
  if (urls?.length > 0) {
    dataWithUrls = data?.map((d, i) => ({
      ...d,
      url: urls[i]?.url,
    }));
  }

  const customTooltip = ({ payload, active }) => {
    if (!active || !payload || !payload.length) return null;
  
    const dataItem = payload[0].payload; // Assuming the URL is stored in the payload
    const url = dataItem.url; // Access the URL from the data item
  
    return (
      <div className="w-56 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
        {payload.map((category, idx) => (
          <div key={idx} className="flex flex-1 space-x-2.5">
            <div className={`w-1 flex flex-col bg-primary rounded`} />
            <div className="space-y-1">
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">{category.value} bpm</p>
              {url && <a href={url} className="text-blue-600 hover:text-blue-800 visited:text-purple-600">{url}</a>}
            </div>
          </div>
        ))}
      </div>
    );
  };
  

  return (
    <div className="text-sm flex flex-col space-y-5 border border-gray-300 rounded-lg py-5 px-3">
      <h1 className="text-lg font-semibold ml-3 text-gray-800">{title}</h1>
      <AreaChart
        className="h-72 w-full"
        data={dataWithUrls?.reverse()}
        index="id"
        categories={[category]}
        colors={["red"]}
        curveType="natural"
        showLegend={false}
        valueFormatter={valueFormatter}
        customTooltip={customTooltip}
      />
    </div>
  );
};
export default CustomAreaChart;
