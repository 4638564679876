const basicYTInfo = [
  {
    title: "Total Videos",
    value: "channelTotalVideos",
  },
  {
    title: "Subscribers",
    value: "numberOfSubscribers",
  },
  {
    title: "Total Views",
    value: "channelTotalViews",
  },
  {
    title: "Country",
    value: "channelLocation",
  },
  {
    title: "Channel Type",
    value: "Tech",
  },
  {
    title: "Channel Created",
    value: "channelJoinedDate",
  },
];
export default basicYTInfo;
