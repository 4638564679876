import React, { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import Brief from "./Brief";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import { BiArrowBack } from "react-icons/bi";
import ConfirmationModal from "./ConfirmationModal";
import { IoCreateSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const InfoHeader = ({
  title,
  subtitle,
  buttonLabel,
  placeholder,
  generateFunction,
  backButton,
  backArrow,
  brief,
  briefTitle,
  briefData,
  creditsRequired,
  confirmationMessage,
  loading,
}) => {
  const [input, setInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);
  const creditsData = useSelector((state) => state.stripe.creditsData);

  const navigate = useNavigate();
  const confirmation = () => {
    if (creditsData?.credit_balance < creditsRequired) {
      toast.error("You don't have enough credits to perform this action");
      return;
    }
    if (buttonLabel) {
      if (input) {
        setShowModal(true);
      } else {
        toast.error("Please enter something!");
      }
    }
  };

  const goback = () => {
    setGoBackModal(true)
    // window.history.back();
  }

  const isMobileScreen = window.innerWidth < 768;
  return (
    <>
      {
        goBackModal && (
          <ConfirmationModal
            heading={"Go Back"}
            message={"Are you sure you want to go back?"}
            onCancel={() => setGoBackModal(false)}
            onConfirm={() => {
              setGoBackModal(false);
              navigate(-2);
              // window.history.back();
            }}
          />
        )
      }
      {showModal && (
        <ConfirmationModal
          heading={"Credit Usage Confirmation"}
          message={`This will cost you approximately ${creditsRequired} credits. Are you sure you want to continue?`}
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            generateFunction(input);
            setShowModal(false);
          }}
        />
      )}
      <div className={`flex items-center justify-between w-full ${isMobileScreen ? "px-2" : "px-6"} py-4`}>
        <div className="flex items-center gap-x-4">
          {backArrow && <BiArrowBack
            className="h-6 w-6 cursor-pointer"
            onClick={() => goback()}
          />}
          {backButton && (
            <Link
              className="p-3 bg-gray-100 rounded-full"
              to={"/dashboard/aioptimization"}
            >
              <BsChevronLeft className="h-4 w-4" />
            </Link>
          )}
          <div>
            <h1 className="title">{title}</h1>
            <p className="text-[#6B7280]">{subtitle}</p>
          </div>
        </div>
        {/* <AiOutlineInfoCircle className="w-6 h-6 text-[#71717A]" /> */}
      </div>
      <div className="px-3">
        {brief && <Brief title={briefTitle} points={briefData} />}
      </div>
      {buttonLabel ? (
        <div className="px-6 py-4">
          <p className="my-3">{buttonLabel}</p>
          <div className={`flex items-center space-x-2 ${isMobileScreen && "flex-col"}`}>
            <input
              className={`bg-[#F9FAFB] rounded-xl ${isMobileScreen ? "w-full mb-2" : "w-1/3"} placeholder-gray-400 text-gray-600 outline-none py-2 px-5 border border-[#E5E7EB]`}
              placeholder={placeholder}
              onChange={(e) => setInput(e.target.value)}
              value={input}
              maxLength={200}
            />
            <button
              className="flex items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
              onClick={confirmation}
            >
              {loading ? (
                <>
                  <div className="flex items-center gap-x-2">
                    <div className="animate-spin h-5 w-5 border-t-2 border-b-2 border-white rounded-full"></div>
                    <p>Loading...</p>
                  </div>
                </>
              ) : (
                <>
                  <IoCreateSharp />
                  <p>Generate
                    {/* ({creditsRequired} Credits) */}
                  </p>
                </>
              )}

            </button>

          </div>
        </div>
      ) : null}
    </>
  );
};

export default InfoHeader;
