import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { IoIosAlert } from "react-icons/io";

const AuditInfoCard = ({
  title,
  valid_description,
  invalid_description,
  valid,
}) => {
  const isMobileScreen = window.innerWidth < 768;

  return (
    <div className={`p-3 rounded-lg border border-gray-300 flex flex-col`}
    style={isMobileScreen ? {width: "48%", marginBottom: "12px"} : {minWidth: "30%"}}
    >
      <div className="flex w-full">
        <h2 className="font-bold w-fit">{title}</h2>
        <p className="ml-auto">
          {valid === "High" || valid === true ? (
            <BsFillCheckCircleFill className="text-green-600 w-6 h-6" />
          ) : valid === "Low" ? (
            <IoIosAlert className="text-yellow-500 w-7 h-7" />
          ) : (
            <MdCancel className="text-primary w-6 h-6" />
          )}
        </p>
      </div>
      {valid !== true && valid !== false ? (
        <p className="subtitle">
          {valid === "High"
            ? "Your page has high videos frequency"
            : valid === "Low"
            ? "Your page has low videos frequency"
            : "Your page has very low videos frequency"}
        </p>
      ) : (
        <p className="subtitle">
          {valid ? valid_description : invalid_description}
        </p>
      )}
    </div>
  );
};

export default AuditInfoCard;
