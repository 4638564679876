import { toast } from "sonner";

const formatDate = (originalDate) => {
    var formattedDate = "";

    if (originalDate) {
        const currentDate = new Date();
        const dateParts = originalDate.split("T")[0].split("-");
        const [year, month, day] = dateParts.map(Number);

        if (
            year === currentDate.getFullYear() &&
            month === currentDate.getMonth() + 1 &&
            day === currentDate.getDate()
        ) {
            formattedDate = "Today";
        } else {
            const yesterday = new Date(currentDate);
            yesterday.setDate(currentDate.getDate() - 1);

            if (
                year === yesterday.getFullYear() &&
                month === yesterday.getMonth() + 1 &&
                day === yesterday.getDate()
            ) {
                formattedDate = "Yesterday";
            } else {
                formattedDate = `${day}-${month}-${year}`;
            }
        }
    }

    return formattedDate;
};

const handleTagClick = (tag, type) => {
    // Create a temporary textarea element to copy the text
    const textArea = document.createElement('textarea');
    textArea.value = tag;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select the text in the textarea
    textArea.select();
    textArea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textArea);

    // Notify the user that the text has been copied
    toast.success(`"${type}" copied to clipboard`);
};

export { formatDate, handleTagClick };