import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const handlePayment = (priceId) => {
    return async (dispatch) => {
        const response = await authenticatedInstance.post(process.env.REACT_APP_BASE_URL + "/stripe/create-checkout-session", {
            // lookup_key: event.target.lookup_key.value,
            priceId: priceId
        });
        const url = response.data;
        window.location.href = url
    }
}

export const creditsData = () => async (dispatch) => {
    try {
        const { data, status } = await authenticatedInstance.get('/stripe/creditsData')
        if (status === 200) {
            dispatch({
                type: "UPDATE_STRIPE",
                payload: data,
            });

            dispatch({
                type: "UPDATE_CURRENT_PLAN",
                payload: data?.currentPlan,
            });

            dispatch({
                type: "SUBSCRIPTION_MODAL",
                payload: data?.showSubscriptionModal,
            })
        }
    } catch (error) {
        console.log(error, "Error in get settings")
        toast.error("Couldn't update credits")
    }
}

export const handleOneTimePayment = (priceId) => {
    return async (dispatch) => {
        try {
            const response = await authenticatedInstance.post(process.env.REACT_APP_BASE_URL + "/stripe/one-time-checkout-session", {
                // lookup_key: event.target.lookup_key.value,
                priceId: priceId
            });

            const url = response.data;
            window.location.href = url
        } catch (error) {
            console.error('Error in handleOneTimePayment:', error);
        }
    }
}


export const handlePortalSession = async (plan, setLoading) => {
    const response = await authenticatedInstance.post("/stripe/create-customer-portal-session", {
        plan: plan
    });
    // console.log(response.data)
    const url = response?.data;
    window.open(url, "_blank");
    if(setLoading){
        setLoading(null)
    }
}

export const handleEndFreeTrial = async () => {
    try {
        const response = await authenticatedInstance.get("/stripe/end-free-trial")
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}
