import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const Header = ({ step }) => {
  return (
    <div className="flex flex-col space-y-5">
      <div className="flex justify-between items-center">
        <p className="subtitle">Step {step}</p>
        {/* <AiOutlineInfoCircle className="w-6 h-6 text-[#71717A]" /> */}
      </div>
      <h1 className="title">Create YouTube Scripts with AI in Minutes</h1>
      <p className="subtitle">
        Write amazing scripts for your YouTube videos with the help of AI. Just
        enter your topic, type, keywords, and tone, and let the AI do the rest.
      </p>
    </div>
  );
};

export default Header;
