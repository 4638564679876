import React, { useEffect } from "react";
import InfoHeader from "../../components/InfoHeader";
import cardData from "../../data/cardData";
import ServiceCard from "../../components/ServiceCard";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";

const VideoOptimize = () => {
  useEffect(() => {
    document.title = "AI video optimization - YTA Studio";
  }, []);

  const isMobileScreen = window.innerWidth < 768;
  return (
    <div>
      <div
        className={`px-5 flex "w-full" justify-between items-center font-Inter`}
        style={{
          paddingTop: "0.875rem",
        }}
      >
        <div className={`flex items-center space-x-3 ${isMobileScreen ? "w-full" : "w-[60%]"}`}>
          <p className="title">AI Tools for YouTube</p>
        </div>
      </div>
      <p className="text-[#6B7280] px-5">
        Boost your YouTube channel with AI-powered tools that help you
        create, optimize, and promote your videos.
      </p>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />

      {/* <InfoHeader
subtitle={"AI-powered tools that help you create, optimize, and promote your videos."}
/> */}
      <div className="flex flex-wrap w-[90%] py-3 px-2"
        style={
          isMobileScreen ? {
            marginLeft: "auto",
            marginRight: "auto"
          } : {}
        }
      >
        {cardData.map(({ title, subtitle, icon, src }, i) => {
          return (
            <ServiceCard
              title={title}
              subtitle={subtitle}
              icon={icon}
              src={src}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VideoOptimize;
