import { useState, useEffect } from 'react';
import "../assets/styles/progress.css";

const customTheme = {
    progress: {
        color: {
            primary: 'bg-red-500 hover:bg-red-600',
        },
    },
};

const Component = ({ setShowProgress }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const maxProgress = 100;
        const timeInterval = 35 * 1000; // 35 seconds in milliseconds
        let startTime = Date.now();

        const updateProgress = () => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            const newProgress = (elapsedTime / timeInterval) * 100;
            setProgress(newProgress >= maxProgress ? maxProgress : Math.floor(newProgress));
        };

        const progressInterval = setInterval(updateProgress, 1000);

        return () => {
            clearInterval(progressInterval);
        };
    }, [setShowProgress]);

    return (
        <div className="w-[70%]">
            {/* <Progress
                theme={customTheme}
                progress={progress}
                progressLabelPosition="inside"
                textLabel=""
                textLabelPosition="outside"
                size="xl"
                color="red"
                labelProgress
                labelText
            /> */}
            <div className="w-full bg-gray-200 rounded-full h-4.5 mb-4 dark:bg-gray-200">
                <div className="bg-red-600 h-4.5 rounded-full bg-primary" style={{ width: `${progress}%` }}>
                    {progress === 100 ? (
                        <div className="text-white rounded-full text-center animate-stripe" style={{ width: "100%" }}>
                            Loading...
                        </div>
                    ) : (
                        <div className="text-white rounded-full text-center animate-stripe" style={{ width: "100%" }}>
                            {progress}%
                        </div>
                    )}
                </div>
            </div>


        </div>
    );
};

export default Component;
