const initState = {
    generatedScripts: [],
    loading: false,
}


export default (state = initState, action) => {
    switch (action.type) {
        case "GET_GENERATED_SCRIPTS":
            return {
                ...state,
                generatedScripts: action.payload,
            };
        case "CHANGE_LOADING_STATE":
            return {
                ...state,
                loading: !state.loading,
            };
        default:
            return state;
    }
}