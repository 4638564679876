import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ title, subtitle, icon, src }) => {
  const navigate = useNavigate();

  const creditsHandler = (title) => {
    switch (title) {
      case "Tag Generator":
        return process.env.REACT_APP_TAG_GENERATOR_CREDITS;
        break;
      case "Title Generator":
        return process.env.REACT_APP_TITLE_GENERATOR_CREDITS;
        break;
      case "Description Generator":
        return process.env.REACT_APP_DESCRIPTION_GENERATOR_CREDITS;
        break;
      case "Tag Extractor":
        return process.env.REACT_APP_TAG_EXTRACTOR_CREDITS;
        break;
      case "Hashtag Generator":
        return process.env.REACT_APP_HASHTAG_GENERATOR_CREDITS;
        break;
    }
  }

  return (
    <div className="flex flex-col w-[350px] border-2 border-[#EAECF0] p-3 rounded-lg space-y-3 m-3">
      <div className="text-xl flex items-center space-x-2">
        {icon}
        <p className="text-[#344054] font-semibold">{title}</p>
      </div>
      <p className="text-[#6B7280]">{subtitle}</p>
      <div className="flex items-center space-x-2">
      <button
        className="bg-primary text-white rounded-lg py-1 px-2 outline-none w-1/3 text-sm"
        onClick={() => navigate(src)}
      >
        View
      </button>
      {/* <span className="text-primary" style={{
        width: 'fit-content',
        display: 'inline',
        margin: '0'
      }}
      >
        &nbsp;&nbsp;({creditsHandler(title)} Credits)
        </span> */}
      </div>
    </div>
  );
};

export default ServiceCard;
