import { BsTag } from "react-icons/bs";
import { HiHashtag } from "react-icons/hi";
import { VscSymbolKeyword } from "react-icons/vsc";
const cardData = [
  {
    title: "Tag Generator",
    subtitle: "Generate tags for your YouTube video.",
    icon: <BsTag />,
    src: "/dashboard/tag-generator",
  },
  {
    title: "Title Generator",
    subtitle: "Make titles that will grab attention.",
    icon: (
      <svg
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.17188 0.125C0.861074 0.125 0.563003 0.248465 0.343234 0.468234C0.123465 0.688003 0 0.986074 0 1.29688L0 3.64062C0 3.95143 0.123465 4.2495 0.343234 4.46927C0.563003 4.68903 0.861074 4.8125 1.17188 4.8125C1.48268 4.8125 1.78075 4.68903 2.00052 4.46927C2.22028 4.2495 2.34375 3.95143 2.34375 3.64062V2.46875H6.64062V16.5312H5.85938C5.54857 16.5312 5.2505 16.6547 5.03073 16.8745C4.81097 17.0943 4.6875 17.3923 4.6875 17.7031C4.6875 18.0139 4.81097 18.312 5.03073 18.5318C5.2505 18.7515 5.54857 18.875 5.85938 18.875H9.76562C10.0764 18.875 10.3745 18.7515 10.5943 18.5318C10.814 18.312 10.9375 18.0139 10.9375 17.7031C10.9375 17.3923 10.814 17.0943 10.5943 16.8745C10.3745 16.6547 10.0764 16.5312 9.76562 16.5312H8.98438V2.46875H13.2812V3.64062C13.2812 3.95143 13.4047 4.2495 13.6245 4.46927C13.8443 4.68903 14.1423 4.8125 14.4531 4.8125C14.7639 4.8125 15.062 4.68903 15.2818 4.46927C15.5015 4.2495 15.625 3.95143 15.625 3.64062V1.29688C15.625 0.986074 15.5015 0.688003 15.2818 0.468234C15.062 0.248465 14.7639 0.125 14.4531 0.125H1.17188ZM12.5 9.10938C12.5 8.79857 12.6235 8.5005 12.8432 8.28073C13.063 8.06097 13.3611 7.9375 13.6719 7.9375H23.8281C24.1389 7.9375 24.437 8.06097 24.6568 8.28073C24.8765 8.5005 25 8.79857 25 9.10938C25 9.42018 24.8765 9.71825 24.6568 9.93801C24.437 10.1578 24.1389 10.2812 23.8281 10.2812H13.6719C13.3611 10.2812 13.063 10.1578 12.8432 9.93801C12.6235 9.71825 12.5 9.42018 12.5 9.10938ZM12.5 14.5781C12.5 14.2673 12.6235 13.9693 12.8432 13.7495C13.063 13.5297 13.3611 13.4062 13.6719 13.4062H23.8281C24.1389 13.4062 24.437 13.5297 24.6568 13.7495C24.8765 13.9693 25 14.2673 25 14.5781C25 14.8889 24.8765 15.187 24.6568 15.4068C24.437 15.6265 24.1389 15.75 23.8281 15.75H13.6719C13.3611 15.75 13.063 15.6265 12.8432 15.4068C12.6235 15.187 12.5 14.8889 12.5 14.5781Z"
          fill="#475467"
        />
      </svg>
    ),
    src: "/dashboard/title-generator",
  },
  {
    title: "Hashtag Generator",
    subtitle: "Find hashtags to boost your video ranking.",
    icon: <HiHashtag />,
    src: "/dashboard/hashtag-generator",
  },
  {
    title: "Tag Extractor",
    subtitle: "Extract tags from a youtube video",
    icon: <VscSymbolKeyword />,
    src: "/dashboard/tag-extractor",
  },
  {
    title: "Description Generator",
    subtitle: "Write descriptions that will hook your audience.",
    icon: <VscSymbolKeyword />,
    src: "/dashboard/description-generator",
  },
];
export default cardData;
