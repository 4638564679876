import React, { useRef, useState, useEffect } from "react";
import SearchCard from "../../components/SearchCard";
import SearchCardSkeleton from "../../components/Skeleton/SearchCardSkeleton";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { Modal } from "../../components/Modal";
import { PiPaperPlaneTiltBold } from "react-icons/pi";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { searchMore } from "../../redux/actions/ideaActions";
import { AiOutlineSync, AiOutlineSearch } from "react-icons/ai";
import AnimatedExample from "../../components/ProgressBar";
import { useNavigate, useParams } from "react-router-dom";
import { getSearchResult } from "../../redux/actions/ideaActions";
import { IoCreateSharp } from "react-icons/io5";
import Tooltip from '@mui/material/Tooltip';

const StartSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("search");
  const data = useSelector((state) => state.ideas.searchResult);
  const searchTerm = useSelector((state) => state.ideas.searchTerm);
  const [loading, setLoading] = useState(false);
  const [soft_unlocked, setSoft_unlocked] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  let searchId = useSelector((state) => state.ideas.searchId);
  const getResultLoading = useSelector((state) => state.ideas.getResultLoading);
  const [cardHeight, setCardHeight] = useState('30rem');

  const creditsData = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsData?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);

  useEffect(() => {
    // Calculate the change in credits
    const creditChange = credit_balance - prevCreditsRef.current;

    // Display notification when credits_available changes
    if (creditChange !== 0) {
      const message = `${Math.abs(creditChange)} credits deducted for the Search`;

      if (creditChange < 0) {
        toast.success(message)
      }
    }

    // Update the ref to the current value for the next calculation
    prevCreditsRef.current = credit_balance;
  }, [credit_balance]);

  let params = useParams();
  let id = params.id

  const updateCardHeight = () => {
    const cardParentDiv = document.querySelector('.cardParentDiv');
    const cardParentDivWidth = cardParentDiv?.offsetWidth;

    if (cardParentDivWidth < 784) {
      setCardHeight('36rem');
    } else if (cardParentDivWidth < 1024) {
      setCardHeight('32rem');
    } else {
      if (window.innerWidth < 1442) {
        setCardHeight('32rem');
      } else {
        setCardHeight('30rem');
      }
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      dispatch({
        type: "GET_RESULT_LOADING",
        payload: true
      })
      dispatch(getSearchResult(id));
    }
  }, [id]);

  useEffect(() => {
    updateCardHeight(); // Initial update
    window.addEventListener('resize', updateCardHeight); // Listen for window resize
    return () => {
      window.removeEventListener('resize', updateCardHeight); // Clean up the event listener
    };
  }, []);
  useEffect(() => {
    document.title = "Start your search - YTA Studio";
  }, []);

  const getExistingUrls = async (channelUrls) => {
    const res = await authenticatedInstance.post(
      process.env.REACT_APP_BASE_URL + "/search/getExistingUrls",
      {
        channelUrls,
      }
    );

    return res.data;
  };

  const dispatchSearchMore = async () => {
    setLoading(true);
    handleScroll();
    try {
      await dispatch(searchMore(searchId));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };


  const startSearch = async () => {

    if (searchTerm === "") {
      toast.error("searchTerm can't be empty!");
      return;
    }

    if (credit_balance < process.env.REACT_APP_SEARCH_TERM) {
      toast.error("You don't have enough credits to start the search!");
      return;
    }

    setLoading(true);
    dispatch({
      type: "GET_SEARCH_RESULT",
      payload: {
        searchTerm: searchTerm,
        data: [],
        searchId: null
      }
    });
    // navigate("/dashboard/start-search/");
    setShowProgress(true);
    authenticatedInstance
      .post(process.env.REACT_APP_BASE_URL + "/search", {
        searchTerm,
        balanceReduction: 30,
        creditReduction: 3,
      })
      .then((res) => {

        if (res?.data?.status === false) {
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }

        let result = res?.data;
        dispatch({
          type: "UPDATE_STRIPE",
          payload: result?.stripeResult,
        });

        dispatch({
          type: "GET_SEARCH_RESULT",
          payload: {
            searchId: result?.searchId,
            data: result?.items,
            searchTerm: result?.searchTerm,
          }
        });
        navigate("/dashboard/start-search/" + result?.searchId);
        setLoading(false);
        // setsearchTerm("");
      })
      .catch((e) => {
        console.log(e.message);
        toast.error("Some error occured, please try again!");
        setLoading(false);
      });

  };

  useEffect(() => {
    if (data?.length > 0) {
      const channelUrls = data.map((obj) => obj.channelUrl);
      getExistingUrls(channelUrls).then((existingUrlObj) => {
        setSoft_unlocked(existingUrlObj);
      });
    }
  }, [data])

  const showModalHandler = (type) => {
    if (
      (type === "loadMore"
        ? creditsData?.credit_balance < process.env.REACT_APP_IDEA_RESEARCH_LOAD_CREDITS
        : creditsData?.credit_balance < process.env.REACT_APP_IDEA_RESEARCH_CREDITS)
    ) {
      toast.error("You don't have enough credits to start the search!");
    }
    else {
      setShowModal(true);
    }
  };

  const handleScroll = () => {
    // Scroll the page by 50 pixels down from the current position
    window.scrollTo(0, document.body.scrollHeight);
  };

  const isMobileScreen = window.innerWidth < 768;

  return (
    <>
      {showModal && (
        <Modal handleClose={() => setShowModal(false)}>
          <div className="flex flex-col items-center justify-center">

            <svg className='mx-auto' width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7617_1695)">
                <mask id="mask0_7617_1695" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                  <path d="M56 0H0V56H56V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_7617_1695)">
                  <path d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z" fill="#FFD7D5" />
                  <path d="M23 19V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H31.4C31.9601 24 32.2401 24 32.454 23.891C32.6422 23.7951 32.7951 23.6422 32.891 23.454C33 23.2401 33 22.9601 33 22.4V20M33 37V30.6C33 30.0399 33 29.7599 32.891 29.546C32.7951 29.3578 32.6422 29.2049 32.454 29.109C32.2401 29 31.9601 29 31.4 29H24.6C24.0399 29 23.7599 29 23.546 29.109C23.3578 29.2049 23.2049 29.3578 23.109 29.546C23 29.7599 23 30.0399 23 30.6V37M37 25.3255V32.2C37 33.8802 37 34.7202 36.673 35.362C36.3854 35.9265 35.9265 36.3854 35.362 36.673C34.7202 37 33.8802 37 32.2 37H23.8C22.1198 37 21.2798 37 20.638 36.673C20.0735 36.3854 19.6146 35.9265 19.327 35.362C19 34.7202 19 33.8802 19 32.2V23.8C19 22.1198 19 21.2798 19.327 20.638C19.6146 20.0735 20.0735 19.6146 20.638 19.327C21.2798 19 22.1198 19 23.8 19H30.6745C31.1637 19 31.4083 19 31.6385 19.0553C31.8425 19.1043 32.0376 19.1851 32.2166 19.2947C32.4184 19.4184 32.5914 19.5914 32.9373 19.9373L36.0627 23.0627C36.4086 23.4086 36.5816 23.5816 36.7053 23.7834C36.8149 23.9624 36.8957 24.1575 36.9447 24.3615C37 24.5917 37 24.8363 37 25.3255Z" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z" stroke="#FEF2F2" stroke-width="8" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_7617_1695">
                  <rect width="56" height="56" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <h1 className="title" style={{
              marginBottom: "16px"
            }}>Are you sure?</h1>
            <p className="text-gray-500 text-center">
              This will generate 20 more Ideas for you and will cost you <span className="font-semibold">{process.env.REACT_APP_SEARCH_TERM} credits</span>. Are you sure you want to continue? </p>
            <div className="flex items-center space-x-2 mt-5">
              <button
                className="flex items-center space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"
                onClick={() => setShowModal(false)}
              >
                <p>Cancel</p>
              </button>
              <button
                className="flex items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
                onClick={() => {
                  setShowModal(false);
                  if (modalType === "loadMore") {
                    dispatchSearchMore();
                  } else {
                    startSearch();
                  }
                }}
              >
                <IoCreateSharp />
                <p>Generate
                  {/* ({modalType === "loadMore" ? process.env.REACT_APP_IDEA_RESEARCH_LOAD_CREDITS : process.env.REACT_APP_IDEA_RESEARCH_CREDITS} Credits)  */}
                </p>
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div className="w-full p-5 cardParentDiv" style={isMobileScreen ?{
        width: "100vw",
      }:{
        maxwidth: "90vw",
      }}>
        <div className={`flex flex-col items-center justify-center space-y-4 ${isMobileScreen ? "mt-8" : "mt-16"}`}>
          <h1 className="title">Create YouTube Scripts with AI in Minutes</h1>
          <p className="text-center subtitle">
            Find the best topics for your YouTube videos based on your niche,
            audience, and goals. Discover what’s trending, what’s popular, and
            what’s missing in your market.
          </p>
          <div className="flex justify-center items-center space-x-3 w-full py-4">
            <input
              className="bg-[#F9FAFB] rounded-xl w-[60%] placeholder-[#6B7280] text-gray-600 outline-none py-2 px-5 border border-[#E5E7EB]"
              placeholder="You search query, name of niche, industry, topic..."
              onChange={(e) => {

                dispatch({
                  type: "GET_SEARCH_RESULT",
                  payload: {
                    searchTerm: e.target.value
                  }
                });
              }}
              value={searchTerm}
              maxLength={200}
            />
            <button
              className={`bg-primary text-white rounded-xl outline-none py-2 ${isMobileScreen ? "px-3" : "px-4"} flex items-center gap-x-2`}
              onClick={() => {
                if (searchTerm === "") {
                  toast.error("searchTerm can't be empty!");
                } else if (searchTerm.length < 3) {
                  toast.error("searchTerm should be atleast 3 Character long!");
                } else {
                  setModalType("search");
                  showModalHandler();
                }
              }}
            >
              {
                isMobileScreen ? null :
                  <AiOutlineSearch className="w-5 h-full" />
              }
              <p>Start Search</p>
            </button>
            {/* <p className=" text-primary mt-auto text-sm p-1 bg-primary-light rounded-md">
              Cost: 3 Credits
            </p> */}
          </div>

          {loading && showProgress &&
            <AnimatedExample setShowProgress={setShowProgress} />}

          {((loading || getResultLoading) && (modalType === "search")) ? (
            <>
              <div className="text-gray-400 animate-pulse flex flex-col items-center justify-center">
                <p className="text-lg">Crawling Youtube & searching for videos...</p>
                <p className="text-sm">(It might take 25 to 35 seconds)</p>
              </div>

              <div className="w-full flex flex-wrap items-center gap-x-3 justify-evenly gap-y-5">
                {[1, 2, 3, 4, 5, 6].map(() => {
                  return <SearchCardSkeleton />;
                })}
              </div>
            </>
          ) : (
            <div className="w-full flex flex-wrap items-center gap-x-3 justify-evenly gap-y-5">
              {data && data.map((d) => {
                return (
                  <SearchCard
                    soft_unlocked={soft_unlocked}
                    key={d?.id}
                    data={d}
                    cardHeight={cardHeight}
                  />
                );
              })}
            </div>
          )}

          <div className="flex justify-center items-center space-x-3 w-full py-4">

            {(data && data.length > 0) && (
              <Tooltip title="Coming Soon" placement="right" arrow>
                <span>
                  <button
                    className={`bg-primary opacity-50 text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2 ${loading && modalType === "loadMore" ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => {
                      setModalType("loadMore");
                      showModalHandler("loadMore");
                    }}
                    disabled={true}
                    title="Coming Soon"
                  >
                    {loading && modalType === "loadMore" ? (
                      <>
                        <div className="flex items-center gap-x-2">
                          <div className="animate-spin h-5 w-5 border-t-2 border-b-2 border-white rounded-full"></div>
                          <p>Loading...</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <AiOutlineSync /> <p>Load More</p>
                      </>
                    )}
                  </button>
                </span>
              </Tooltip>
            )}
          </div>
          {loading && modalType === "loadMore" ? (
            <AnimatedExample setShowProgress={setShowProgress} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default StartSearch;
