const initState = {
  loading: false,
  channelUrl: "",
  basicAuditResult: "",
  deepAuditLoading: false,
  deepAuditResult: "",
  deepUnlockStatus: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "BASIC_AUDIT_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "DEEP_AUDIT_LOADING":
      return {
        ...state,
        deepAuditLoading: !state.deepAuditLoading,
      };
    case "SET_CHANNELURL":
      return {
        ...state,
        channelUrl: action.payload,
      };
    case "SET_BASIC_AUDIT_RESULT":
      return {
        ...state,
        basicAuditResult: action.payload,
      };
      case "SET_DEEP_UNLOCK_STATUS":
        return {
            ...state,
            deepUnlockStatus: action.payload,
        };
    case "SET_DEEP_AUDIT_RESULT":
      return {
        ...state,
        deepAuditResult: action.payload,
      };
    default:
      return state;
  }
};
