import React, { useState, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { DataGrid } from "@mui/x-data-grid";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { TbTableAlias } from "react-icons/tb";
import { deleteScript } from "../../redux/actions/scriptActions";
import { Modal } from "../../components/Modal";
import { formatDate } from "../../helpers/utils";
import { getScriptResult } from "../../redux/actions/scriptActions";
import _debounce from 'lodash/debounce';
import "../../assets/styles/style.css"

export const SearchData = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedCopy, setSelectedCopy] = useState(null);
  const [openedDropdown, setOpenedDropdown] = useState(null);
  const [innerDivHeight, setInnerDivHeight] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // const inventoryData = useSelector((state) => state.scripts.generatedScripts);
  const inventoryData = props.tableData;
  const generatedScriptLoading = useSelector((state) => state.scriptForm.generatedScriptLoading);


  useEffect(() => {
    // Calculate the height of the inner div after it's rendered
    const innerDiv = document.querySelector('.inner-div');
    if (innerDiv) {
      const height = innerDiv.clientHeight;
      setInnerDivHeight(height);
    }

    dispatch({
      type: "SET_SCRIPT_RESULT",
      payload: {
        scriptId: null,
        data: "",
      }
    });

    dispatch({
      type: "GET_SEARCH_RESULT",
      payload: {
        searchId: null,
        searchTerm: "",
        data: [],
      }
    });

  }, []);



  const getInventoryData = async () => {
    dispatch({
      type: "SCRIPT_LOADING",
      payload: true,
    });
    await authenticatedInstance
      .get(process.env.REACT_APP_BASE_URL + "/getGeneratedScripts", {})
      .then((res) => {
        dispatch({
          type: "SCRIPT_LOADING",
          payload: false,
        });
        dispatch({
          type: "GET_GENERATED_SCRIPTS",
          payload: res.data,
        });
        dispatch({
          type: "GENERATED_SCRIPT_LOADING",
          payload: false,
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getInventoryData();
  }, []);

  const customNoDataOverlay = () => {
    return (
      <div className="w-full py-4 flex flex-col items-center justify-center space-y-3 h-full">

        {generatedScriptLoading ? (
          <p className="text-lg font-semibold text-gray-700">
            <div class="loader ease-linear rounded-full border-4 border-t-6 border-gray-300 h-12 w-12"></div>
          </p>
        ) : (
          <>
            <TbTableAlias className="w-10 h-10 text-gray-400" />
            <p className="text-lg font-semibold text-gray-700">
              There is no data to show
            </p>
            <p>Click on create script button to create your first script</p>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // Function to toggle the dropdown
  const toggleDropdown = (id) => {
    if (openedDropdown === id) {
      setOpenedDropdown(null);
    } else {
      setOpenedDropdown(id);
    }
  };

  const deleteModalHandler = (id) => {
    setSelectedCopy(id);
    setShowDeleteModal(!showDeleteModal);

    if (!id) {
      // Close the modal and set openedDropdown to null
      setShowDeleteModal(false);
      setOpenedDropdown(null);
    }
  }

  const deleteCopyHandler = async (id) => {
    // Perform the delete operation here, if needed
    dispatch(deleteScript(id))

    // Close the modal and set openedDropdown to null
    setShowDeleteModal(false);
    setOpenedDropdown(null);
  }

  const columns = [
    {
      field: "title",
      headerName: "Name",
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className={`flex items-center ${isMobileScreen ? "w-[100px]" : "w-[100%]"} h-full px-3 cursor-pointer`}
            onClick={() => dispatchScriptResult(params.row?.id)}
          >
            <div className="font-bold text-[13.5px] text-[#000000de] truncate capitalize"
            style={{
              width: "fit-content"
            }}>
              {params.row?.title}
            </div>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3"
            onClick={() => dispatchScriptResult(params.row?.id)}
          >
            {formatDate(params.row?.createdAt)}
          </div>
        );
      },
    },
    {
      field: "Length",
      headerName: "Length",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3"
            onClick={() => dispatchScriptResult(params.row?.id)}
          >
            {(params.row?.video_length)}
          </div>
        );
      },
    },
    {
      field: "credits_used",
      headerName: "Credits Used",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3"
            onClick={() => dispatchScriptResult(params.row?.id)}
          >
            {/* if 0 or no credits, show '-' */}
            {params.row?.credits_used ? params.row?.credits_used : '-'}
          </div>
        );
      },
    },
    {
      field: "Type",
      headerName: "Type",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            className="flex items-center w-[100%] h-full px-3 cursor-pointer font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3"
            onClick={() => dispatchScriptResult(params.row?.id)}
          >
            {params.row?.video_type}
          </div>
        );
      },
    },
    {
      field: " ",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="w-3/4 flex"
          style={{
            marginLeft: "auto",
          }}>
            <svg
              onClick={() => toggleDropdown(params.row?.id)}
              className='w-8 py-1.5 mr-7 hover:bg-slate-200 px-1.5 mt-2 rounded-lg hover:cursor-pointer'
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
            </svg>
            {openedDropdown === params.row?.id && (
              <ul className="origin-top-right absolute  ml-2 mt-10 w-32 rounded-md z-40 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className='p-1'>
                  <div className='cursor-pointer'>
                    <button
                      id={'tags'}
                      onClick={() => deleteModalHandler(params.row?.id)}
                      className="hover:bg-slate-100 hover:text-slate-900 text-slate-700 group flex rounded-md items-center w-full px-2 py-2 text-sm">
                      <svg className="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                      Delete
                    </button>
                  </div>
                </div>
              </ul>
            )}
          </div>
        );
      },
    },
  ];

  const dispatchScriptResult = (id) => {
    navigate("/dashboard/script-result/" + id);
  };

  const isMobileScreen = window.innerWidth < 768;
  return (
    <>
      {showDeleteModal && (
        <Modal handleClose={() => deleteModalHandler(null)}>
          <div className="flex flex-col space-y-3">
            <h1 className="title">Confirm Script Deletion</h1>
            <p className="subtitle text-sm">
              This action is irreversible. Are you sure you want to delete the script?
            </p>
            <div className="flex items-center ml-auto text-sm pt-1 font-medium space-x-3">
              <button
                className="flex cursor-pointer items-center space-x-2 py-2 px-3 bg-white border border-gray-300 text-gray-500 rounded-xl"
                onClick={() => deleteModalHandler(null)}
              >
                <p>Cancel</p>
              </button>
              <button
                className="flex cursor-pointer items-center space-x-2 py-2 px-3 bg-primary text-white rounded-xl"
                onClick={() => {
                  deleteCopyHandler(selectedCopy);
                }}
              >
                <p>Delete</p>
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className={`fade-in font-Inter`}>
        <div className="w-full pb-1.5 border-[1px] border-[#EAECF0]"
        style={isMobileScreen ? {
          width: "100vw",
          overflowX: "scroll",
        }: {}}>
        <div className={`${isMobileScreen ? "h-[16rem]" : "h-[42rem]"}`}
          style={isMobileScreen ? {
            width: "150vw",
          } : { width: "100%" }}>
            <DataGrid
              sx={{
                whiteSpace: 'nowrap', // Prevent text wrapping
                overflowX: "scroll",
                border: 0,
                "& .css-17jjc08-MuiDataGrid-footerContainer": {
                  borderTop: 0,
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "700",
                  fontSize: "13px",
                  lineHeight: "18px",
                  fontFamily: "Inter",
                },
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-selectedRowCount": {
                  visibility: "hidden",
                },
                "& .css-17jjc08-MuiDataGrid-footerContainer": {
                  borderTop: 0,
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1.15rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  paddingLeft: "10px",
                  backgroundColor: "#F2F5F7",
                },
                "& .MuiDataGrid-cell": {
                  padding: "0px",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "#F3F4F6",
                },
                "& .MuiDataGrid-row.Mui-selected:hover": {
                  backgroundColor: "#F3F4F6",
                },
              }}
              rows={inventoryData || []}
              columns={columns}
              loading={loading}
              getRowId={(row) => row?.id || Math.random()}
              slots={{
                noRowsOverlay: customNoDataOverlay,
                noResultsOverlay: customNoDataOverlay,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'Name', sort: 'desc' }],
                  sortModel: [{ field: 'Date', sort: 'desc' }],
                  sortModel: [{ field: 'Type', sort: 'desc' }],
                },
                pagination: { paginationModel: { pageSize: 20 } },
              }}
              pageSizeOptions={[20, 30, 50]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
