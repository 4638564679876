import React, { useRef, useEffect, useState } from "react";
import InfoHeader from "../../components/InfoHeader";
import basicYTInfo from "../../data/YTAudit/BasicInfo";
import {
  BestVideos,
  Profile,
  content,
  engagement,
} from "../../data/YTAudit/AuditInfo";
import AuditInfoCard from "../../components/AuditInfoCard";
import CardWithLogo from "../../components/CardWithLogo";
import Growth from "../../data/YTAudit/Growth";
import GrowthCard from "../../components/GrowthCard";
import Ranks from "../../data/YTAudit/Ranks";
import CustomAreaChart from "../../components/CustomAreaChart";
import { Subscribers, Views } from "../../data/YTAudit/ChartData";
import VideoCard from "../../components/VideoCard";
import { FiLock } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../helpers/formatNumber";
import { Link, useParams } from "react-router-dom";
import BasicAuditSkeleton from "../../components/Skeleton/BasicAuditSkeleton";
import axios from "axios";
import platforms from "../../data/socialMedia";
import DeepunlockSkeleton from "../../components/Skeleton/DeepunlockSkeleton";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { Modal } from "../../components/Modal";
import { toast } from "sonner";
import { BiArrowBack } from "react-icons/bi";
import ProgressBar from "../../components/ProgressBar";
import { getSoftUnlockDataById } from "../../redux/actions/auditActions";
import AreaChartWithCustomTooltip from "../../components/AreaChartWithCustomTooltip";

const YTAuditBasic = () => {
  const [showModal, setShowModal] = useState(false);
  const [deepUnlockData, setDeepUnlockData] = useState({ status: false });
  const [unlock, setUnlock] = useState(false);
  const [tag, setTag] = useState("");
  const deepUnlockStatus = useSelector((state) => state.audit.deepUnlockStatus);
  const dispatch = useDispatch();
  const {
    channelUrl,
    loading,
    basicAuditResult,
    channelId,
    deepAuditResult,
    deepAuditLoading,
  } = useSelector((state) => state.audit);
  const findTag = () => {
    const match = channelUrl.match(/@([^/]+)/);
    if (match && match[1]) {
      const username = match[1];
      setTag(username);
    } else {
      setTag(basicAuditResult?.channelName);
    }
  };

  const creditsData = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsData?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);

  let params = useParams();
  const id = params['*'];

  // useEffect(() => {
  //   // Calculate the change in credits
  //   const creditChange = credit_balance - prevCreditsRef.current;

  //   // Display notification when credits_available changes
  //   if (creditChange !== 0) {
  //     let message = ``;
  //     if (creditChange === 1 || creditChange === '1') {
  //       message = `${Math.abs(creditChange)} credit deducted for Unlock`;
  //     } else {
  //       message = `${Math.abs(creditChange)} credits deducted for Unlock`;
  //     }
  //     if (creditChange < 0) {
  //       toast.success(message)
  //     }
  //   }

  //   // Update the ref to the current value for the next calculation
  //   prevCreditsRef.current = credit_balance;
  // }, [credit_balance]);

  useEffect(() => {
    if (id !== undefined && basicAuditResult?.channelName === undefined) {
      dispatch({
        type: "GET_RESULT_LOADING",
        payload: true
      })
      dispatch(getSoftUnlockDataById(id));
    }
  }, [id]);

  useEffect(() => {
    document.title = "Youtube channel audit - YTA Studio";
    getDeepUnlock();
  }, []);

  const getDeepUnlock = async () => {
    const res = await authenticatedInstance.post(
      process.env.REACT_APP_BASE_URL + "/ytaudit/getDeepUnlock",
      {
        channelUrl,
      }
    );
    setDeepUnlockData(res?.data);
    if (res.data.status === true) {
      dispatch({
        type: "SET_DEEP_AUDIT_RESULT",
        payload: res?.data?.data.deep_unlock_data,
      });
    }
  };
  useEffect(() => {
    if (basicAuditResult?.channelName && channelUrl) {
      findTag();
    }
    // } else {
    //   getDeepUnlock();
    // }
  }, [basicAuditResult, channelUrl]);

  const handleHardUnlock = async () => {
    dispatch({
      type: "DEEP_AUDIT_LOADING",
      payload: true,
    });
    authenticatedInstance
      .post(process.env.REACT_APP_BASE_URL + "/ytaudit/hardunlock", {
        channelName: basicAuditResult?.channelName,
        channelUrl: channelUrl,
      })
      .then((res) => {
        dispatch({
          type: "SET_DEEP_AUDIT_RESULT",
          payload: res?.data?.data?.data,
        });
        dispatch({
          type: "UPDATE_STRIPE",
          payload: res?.data?.stripeResult,
        })
        setUnlock(true);
        dispatch({
          type: "DEEP_AUDIT_LOADING",
          payload: false,
        });
      })
      .catch((e) => {
        console.log(e.message);
        toast.error("Some error occured, please try again!");
        dispatch({
          type: "DEEP_AUDIT_LOADING",
          payload: false,
        });
      });
  };

  const isMobileScreen = window.innerWidth < 768;
  return (
    <>
      <div style={isMobileScreen ?{
        width: "100vw",
      }:{
        maxwidth: "90vw",
      }}>

        <InfoHeader
          title={"Youtube Channel Audit"}
          subtitle={"Find detailed audit and analysis for your youtube channel"}
          backArrow={true}
        />

        {loading ? (
          <>

            <div className="text-gray-400 animate-pulse flex flex-col justify-center p-5">

              <p className="text-xl">Auditing the Youtube Channel...</p>
              <p className="mb-4">(It might take 30 to 40 seconds)</p>

              <ProgressBar />
            </div>

            <BasicAuditSkeleton />
          </>
        ) : (
          <div className="p-5 flex flex-col space-y-6">
            <div className="w-full flex items-center"
            style={isMobileScreen ? {
              textAlign: "center",
              flexWrap: "wrap",
            } : {}}>
              <img
                src={basicAuditResult?.thumbnailUrl}
                className="h-[15rem] w-[25rem] rounded-lg"
              />
              <div className={`flex flex-col space-y-3 ${isMobileScreen ? "w-full" : "w-1/2"} px-5`}>
                <div className="flex flex-col space-y-1">
                  <Link className="title" to={channelUrl} target="_blank">
                    {basicAuditResult?.channelName}
                  </Link>
                  <Link className="subtitle" to={channelUrl} target="_blank">
                    @{tag}
                  </Link>
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="subtitle">
                    {basicAuditResult?.valid_channel_description
                      ? basicAuditResult?.channelDescription.substring(0, 100) +
                      "..."
                      : basicAuditResult?.channelDescription}
                  </p>
                  <p className="subtitle">
                    <span className="font-semibold">
                      {formatNumber(basicAuditResult?.numberOfSubscribers)}
                    </span>{" "}
                    Subscribers
                  </p>
                  <p className="subtitle">
                    Date created: {basicAuditResult?.channelJoinedDate}
                  </p>
                </div>
              </div>
            </div>
            <div className="border border-gray-300 flex justify-around items-center p-4 rounded-lg"style={isMobileScreen ? {
                  // flexDirection: "column",
                  flexWrap: "wrap",
                } : {}}>
              {basicYTInfo.map(({ title, value }, i) => {
                return (
                  <div key={i} className="text-center"
                  style={isMobileScreen ? {
                    margin: "0.6rem",
                  } : {}}>
                    <p className="subtitle">{title}</p>
                    <h2 className="font-semibold text-gray-800">

                      {basicAuditResult[value] ? basicAuditResult[value] : "N/A"}
                    </h2>
                  </div>
                );
              })}
            </div>
            <div className="w-full">
              <h1 className="title">Profile</h1>
              <div className="w-full flex items-center justify-between py-4"
                style={isMobileScreen ? {
                  // flexDirection: "column",
                  flexWrap: "wrap",
                } : {}}>
                {Profile.map(
                  (
                    { title, valid_description, invalid_description, valid },
                    i
                  ) => {
                    return (
                      <AuditInfoCard
                        title={title}
                        valid_description={valid_description}
                        invalid_description={invalid_description}
                        valid={basicAuditResult[valid]}
                        key={i}
                      />
                    );
                  }
                )}
              </div>
            </div>
            <div className="w-full">
              <h1 className="title">Content</h1>
              <div className={`w-full flex items-center justify-between py-4`}
                style={isMobileScreen ? {
                  // flexDirection: "column",
                  flexWrap: "wrap",
                } : {}}
              >
                {content.map(
                  (
                    { title, valid_description, invalid_description, valid },
                    i
                  ) => {
                    return (
                      <AuditInfoCard
                        title={title}
                        valid_description={valid_description}
                        invalid_description={invalid_description}
                        valid={valid === true ? valid : basicAuditResult[valid]}
                        key={i}
                      />
                    );
                  }
                )}
              </div>
            </div>
            <div className="w-full flex flex-col space-y-6">
              <h1 className="title">Engagement</h1>
              <div className="w-full flex items-center justify-between"
                style={isMobileScreen ? {
                  // flexDirection: "column",
                  flexWrap: "wrap",
                } : {}}>
                {engagement.map(({ title, value, logo }, i) => {
                  return (
                    <CardWithLogo
                      title={title}
                      subtitle={basicAuditResult[value]}
                      logo={logo}
                      key={i}
                    />
                  );
                })}
              </div>
            </div>
            {/* <div className="w-full flex flex-col space-y-6"> */}

            <AreaChartWithCustomTooltip
              data={basicAuditResult?.per_video_views}
              title={"Views for last 25 videos"}
              valueProperty="views"
            />

            <AreaChartWithCustomTooltip
              data={basicAuditResult?.per_video_likes}
              title={"likes for last 25 videos"}
              valueProperty="likes"
            />

            {/* <CustomAreaChart
              title={"Views for last 25 videos"}
              data={basicAuditResult?.per_video_views}
              urls={basicAuditResult?.urls}
              category={"views"}
            /> */}

            {/* <CustomAreaChart
              title={"likes for last 25 videos"}
              data={basicAuditResult?.per_video_likes}
              urls={basicAuditResult?.urls}
              category={"likes"}
            /> */}

            {/* {!unlock && !deepAuditLoading ? (
              <div className="relative h-32">
                <div className="w-full flex items-center justify-between absolute opacity-20">
                  {Growth.map(({ title, value, growth, increment }, i) => {
                    return (
                      <GrowthCard
                        title={title}
                        value={value}
                        growth={growth}
                        increment={increment}
                      />
                    );
                  })}
                </div>
                
              </div>
            ) : null} */}
            {!unlock && deepAuditLoading && <DeepunlockSkeleton />}
            {unlock ? (
              <>
                <div className="flex flex-col gap-y-5 w-full py-2">
                  <h1 className="title">View Growth</h1>
                  <div className="w-full flex items-center justify-between">
                    {[30, 90, 365].map((val, i) => {
                      return (
                        <GrowthCard
                          key={i}
                          title={`${val} Days`}
                          value={
                            deepAuditResult?.statistics?.growth?.vidviews[val]
                          }
                          increment={
                            deepAuditResult?.statistics?.growth?.vidviews[
                            val
                            ] >= 0
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="flex flex-col gap-y-5 w-full py-2">
                  <h1 className="title">Subscribers Growth</h1>
                  <div className="w-full flex items-center justify-between">
                    {[30, 90, 365].map((val, i) => {
                      return (
                        <GrowthCard
                          key={i}
                          title={`${val} Days`}
                          value={deepAuditResult?.statistics?.growth?.subs[val]}
                          increment={
                            deepAuditResult?.statistics?.growth?.subs[val] >= 0
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="flex flex-col gap-y-5 py-2">
                  <h1 className="title">Social Profiles</h1>
                  <div className="border border-gray-300 flex items-center p-4 rounded-lg gap-x-5">
                    {[
                      "facebook",
                      "twitter",
                      "twitch",
                      "instagram",
                      "linkedin",
                      "discord",
                      "tiktok",
                    ].some(platform => deepAuditResult?.general?.branding?.social[platform]) ? (
                      // Render the section only if at least one platform link is found
                      [
                        "facebook",
                        "twitter",
                        "twitch",
                        "instagram",
                        "linkedin",
                        "discord",
                        "tiktok",
                      ].map((platform, i) => {
                        const platformLink = deepAuditResult?.general?.branding?.social[platform];
                        if (platformLink) {
                          return (
                            <div key={i} className="text-center">
                              <Link to={platformLink}>{platforms[platform]}</Link>
                            </div>
                          );
                        }
                        return null; // Skip rendering if the platform link is not found
                      })
                    ) : (
                      // You can optionally show a message when no platform links are found
                      <p className="text-gray-500">No social platform links found.</p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-y-5 py-2">
                  <h1 className="title">Tags</h1>
                  <div className="flex gap-x-3 gap-y-3 items-center flex-wrap">
                    {deepAuditResult?.misc?.tags.map((e, i) => {
                      return (
                        <p
                          key={i}
                          className="px-4 py-1 rounded-lg bg-gray-100 text-gray-600"
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="flex flex-col gap-y-5 py-2">
                  <h1 className="title">Ranks</h1>
                  <div className="border border-gray-300 flex justify-around items-center p-4 rounded-lg">
                    {Ranks.map(({ title, value }, i) => {
                      return (
                        <div key={i} className="text-center">
                          <p className="subtitle">
                            {value === "channel_type"
                              ? deepAuditResult?.general?.channel_type + " Rank"
                              : title}
                          </p>
                          <h2 className="font-semibold text-gray-800">
                            {deepAuditResult?.ranks
                              ? deepAuditResult?.ranks[value]
                              : null}
                          </h2>
                        </div>
                      );
                    })}
                  </div>
                </div>


                {/* <h1 className="title">Best videos from apple</h1>
              <div className="flex items-center space-x-5 flex-wrap">
                {BestVideos.map((data, i) => {
                  return <VideoCard data={data} key={i} />;
                })}
              </div> */}
              </>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default YTAuditBasic;
