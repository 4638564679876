import { CiUser } from "react-icons/ci";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
const Profile = [
  {
    title: "Channel Title",
    valid_description: "The channel has a description title",
    invalid_description: "The channel doesn't have a good title",
    valid: "valid_channel_title",
  },
  {
    title: "Description",
    valid_description: "The channel has description",
    invalid_description: "The channel doesn't have a good description",
    valid: "valid_channel_description",
  },
  {
    title: "Channel Country",
    valid_description: "The channel has country",
    invalid_description: "The channel doesn't have a country",
    valid: "valid_channel_country",
  },
];

const content = [
  {
    title: "Numer of videos",
    valid_description: "The channel has good number of videos.",
    invalid_description: "The channel has less number of videos",
    valid: "valid_no_of_videos",
  },
  {
    title: "Posting Frequency",
    valid_description: "The channel has good videos frequency",
    invalid_description: "The channel has very low videos frequency.",
    valid: "posting_frequency",
  },
  {
    title: "Video Completeness",
    valid_description: "The channel has all completed videos.",
    invalid_description: "The channel has some incomplete videos",
    valid: true,
  },
];

const engagement = [
  {
    title: "Average Views",
    value: "averageViews",
    logo: <CiUser className="w-8 h-8" />,
  },
  {
    title: "Average Likes",
    value: "averageLikes",
    logo: <AiOutlineLike className="w-8 h-8" />,
  },
  {
    title: "Average Video Comments",
    value: "averageComments",
    logo: <FaRegComment className="w-8 h-8" />,
  },
];

const BestVideos = [
  {
    title: "Introducing iPhone 15 Pro | Apple",
    id: "g1Ll9OlFwEQ",
    url: "https://youtu.be/xqyUdNxWazA?si=nHAX8T-VwkOVrx3k",
    viewCount: 4052,
    date: "2022-08-22",
    likes: 83,
    channelName: "Apple",
    channelUrl: "http://www.youtube.com/@apple",
    numberOfSubscribers: 1980,
    duration: "00:03:15",
    img: "https://img.youtube.com/vi/xqyUdNxWazA/maxresdefault.jpg",
  },
  {
    title: "Introducing iPhone 14 Pro | Apple",
    id: "C0pXaNchNTA",
    url: "https://youtu.be/FT3ODSg1GFE?si=UypjofS5r2wC4EWu",
    viewCount: 9018,
    date: "2023-01-24",
    likes: 233,
    channelName: "Apple",
    channelUrl: "http://www.youtube.com/@apple",
    numberOfSubscribers: 5920,
    duration: "01:19:34",
    img: "https://img.youtube.com/vi/FT3ODSg1GFE/maxresdefault.jpg",
  },
  {
    title: "AirPods Pro | Adaptive Audio. Now playing. | Apple",
    id: "C0pXaNchNTA",
    url: "https://youtu.be/ql6mhhHCldY?si=2A2LXmO0AaMSoyZN",
    viewCount: 9018,
    date: "2023-01-24",
    likes: 233,
    channelName: "Apple",
    channelUrl: "http://www.youtube.com/@apple",
    numberOfSubscribers: 5920,
    duration: "01:19:34",
    img: "https://img.youtube.com/vi/ql6mhhHCldY/maxresdefault.jpg",
  },
];

export { Profile, content, engagement, BestVideos };
