import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const getSoftUnlockDataById = (id) => {
    return async (dispatch) => {
    authenticatedInstance
        .post(process.env.REACT_APP_BASE_URL + "/ytaudit/getSoftUnlockById", {
            id
        })
        .then((res) => {
            dispatch({
                type: "SET_BASIC_AUDIT_RESULT",
                payload: res?.data?.items,
            });
            dispatch({
                type: "SET_DEEP_UNLOCK_STATUS",
                payload: res?.data?.deepUnlockStatus,
            });
            dispatch({
                type: "BASIC_AUDIT_LOADING",
                payload: false,
            });
        })
        .catch((e) => {
            // id status === 400, show the message in the toast
            if (e.response.status === 400) {
                toast.error(e.response.data.message);
            } else {
                toast.error("Some error occured, please try again!");
            }
            dispatch({
                type: "BASIC_AUDIT_LOADING",
                payload: false,
            });
        });
    }
}