import React, { useRef, useEffect, useState } from "react";
import { GoCopy } from "react-icons/go";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import Tiptap from "../../components/TipTap";
import { toast } from "sonner";
import { useSelector, useDispatch } from "react-redux";
import loadingAnimation from "../../assets/images/Loading_Animation.gif";
import LoadingModal from "../../components/LoadingModal";
import { creditsData } from "../../redux/actions/paymentActions";
import { getScriptResult } from "../../redux/actions/scriptActions";
import { fetchLiveData } from "../../redux/actions/scriptActions";
import { connect } from 'react-redux'
import { set } from "date-fns";

export const ScriptResult = (props) => {

  const { fetchLiveData } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editorData, setEditorData] = useState("");
  // const { formDetails, loading } = useSelector((state) => state.scriptForm);
  const streamActive = useSelector((state) => state.scriptForm.streamActive);
  const params = useParams();
  const id = params['*'];
  console.log(id, "id")
  const { liveStreamData, scriptTitle } = useSelector((state) => state.scriptForm);
  const credits = useSelector((state) => state.stripe.creditsData);
  const credit_balance = credits?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Calculate the change in credits
    const creditChange = credit_balance - prevCreditsRef.current;

    // Display notification when credits_available changes
    if (creditChange !== 0) {
      const message = `${Math.abs(creditChange)} credits deducted for Title Generation`;
      console.log(creditChange, "change in credits")
      if (creditChange < 0) {
        toast.success(message)
      }
    }

    // Update the ref to the current value for the next calculation
    prevCreditsRef.current = credit_balance;
  }, [credit_balance]);

  useEffect(() => {
    dispatch(creditsData());
  }, []);

  useEffect(() => {
    console.log("streamActive", streamActive)
    let interval;
    const fetchData = async () => {
      const result = await fetchLiveData(id, setLoading);

      if (result.status) {
        setLoading(true);
        if (result.isStreamEnded) {
          setLoading(false);
          clearInterval(interval)
          dispatch(creditsData());
        }
      }
    };

    console.log("id", id)

    if (id) {
      interval = setInterval(fetchData, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        setLoading(false);
      }
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLiveData(id);
    }
  }, [id])

  const isMobileScreen = window.innerWidth < 768;
  return (
    <>
      <div className={` ${isMobileScreen ? "px-2 py-2" : "px-5 py-4"} z-49 flex w-full justify-between items-center font-Inter`} style={{
        position: 'relative',
        background: 'white',
      }}>
        <div className="flex items-center space-x-5 w-[60%]">
          <BiArrowBack
            className="h-6 w-6 cursor-pointer"
            onClick={() => navigate("/dashboard/create-script")}
          />
          <p className="title capitalize">{scriptTitle}</p>
        </div>
        <div className="ml-auto flex items-center space-x-2">
          <button
            className="bg-primary text-white rounded-xl outline-none py-2 px-4 flex items-center gap-x-2"
            onClick={() => {
              navigator.clipboard.writeText(editorData);
              toast.success("Copied!");
            }}
          >
            <GoCopy /> <p>Copy</p>
          </button>
          {/* <BsThreeDotsVertical className="h-5 w-5" /> */}
        </div>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div className={`${isMobileScreen? 'px-1 py-2' : loading ? 'p-0' : 'p-10'}`}>
        {liveStreamData && liveStreamData.length <= 0 &&

          <div className="h-screen w-full flex justify-center items-center">
            <div className="space-y-3">
              <img src={loadingAnimation} className="m-auto w-24 h-24 align-items" />
              <p className="text-center text-lg font-[500] text-gray-500">Please wait a moment while we generate your script.<br /> This may take a few minutes. </p>
            </div>
          </div>
        }
        <Tiptap setEditorData={setEditorData} />
        {
          loading && liveStreamData && liveStreamData.length > 0 &&
          <>
            <div className='flex space-x-2 justify-center items-center py-2'>
              <span className='sr-only'>Loading...</span>
              <div className='h-3 w-3 bg-gray-700 rounded-full animate-bounce [animation-delay:-0.4s]'></div>
              <div className='h-3 w-3 bg-gray-700 rounded-full animate-bounce [animation-delay:-0.2s]'></div>
              <div className='h-3 w-3 bg-gray-700 rounded-full animate-bounce'></div>
            </div>
          </>
        }
      </div>

    </>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { fetchLiveData }

export default connect(mapStateToProps, mapDispatchToProps)(ScriptResult)
