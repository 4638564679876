import React, { useRef, useState, useEffect } from "react";
import InfoHeader from "../../components/InfoHeader";
import TagSkeleton from "../../components/Skeleton/TagSkeleton";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { handleTagClick } from "../../helpers/utils";
import Tooltip from '@mui/material/Tooltip';

const TagGenerator = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    title: "",
    tags: [],
  });
  const [loading, setLoading] = useState(false);
  const creditsData = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsData?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);

  useEffect(() => {
    // Calculate the change in credits
    const creditChange = credit_balance - prevCreditsRef.current;

    // Display notification when credits_available changes
    if (creditChange !== 0) {
      const message = `${Math.abs(creditChange)} credits deducted Tag Generation`;
      if (creditChange < 0) {
        toast.success(message)
      }
    }

    // Update the ref to the current value for the next calculation
    prevCreditsRef.current = credit_balance;
  }, [credit_balance]);

  useEffect(() => {
    document.title = "Tag generator - YTA Studio";
  }, []);
  const getTags = (title) => {

    setLoading(true);
    authenticatedInstance
      .post(process.env.REACT_APP_BASE_URL + "/tag/generator", {
        title: title,
        balanceReduction: 30,
        creditReduction: 3,
      })
      .then((res) => {

        if(res?.data?.status === false){
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }
        
        setData({
          title,
          tags: res.data.response?.tags,
        });
        dispatch({
          type: "UPDATE_STRIPE",
          payload: res.data.stripeResult,
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error("Some error occured, please try again!");
        setLoading(false);
      });
  };


  return (
    <div className="p-3">
      <InfoHeader
        title={"Tag Generator"}
        subtitle={"Generate tags for your YouTube video."}
        buttonLabel={"Name"}
        placeholder={"Title for your video"}
        generateFunction={getTags}
        creditsRequired={process.env.REACT_APP_TAG_GENERATOR_CREDITS}
        loading={loading}
        confirmationMessage={"This will generate Tags for the given video Title. It will cost you " + process.env.REACT_APP_TAG_GENERATOR_CREDITS + " credits for each request."}
        backButton={true}
        brief={true}
        briefTitle={"Why tag generator ?"}
        briefData={[
          "Tags helps youtube's algorithm to understand the content of your video, making it easily discoverable to more audience",
          "Improved SEO for video",
          "Connect with your target audience easily",
          "Tags help categorize and organize your content. This not only benefits viewers who can find content related to their interests but also helps you, as a content creator, understand which themes and topics are most popular and should be prioritized in your future content.",
        ]}
      />
      {loading && <TagSkeleton />}
      {data?.tags?.length > 0 && (
        <div className="w-full border border-gray-200 rounded-lg p-6 flex flex-col gap-y-5">
          <h1 className=" text-gray-700">
            Recommended tags for{" "}
            <span className="font-semibold">{data.title}</span>
          </h1>

          <div className="flex gap-x-3 gap-y-3 items-center flex-wrap">
            {data.tags.map((e, i) => (
              <Tooltip title="Click to copy" placement="top-end">
                <p
                  key={i}
                  className="px-4 py-1 rounded-lg bg-gray-100 text-gray-600 cursor-pointer hover:bg-gray-200"
                  onClick={() => handleTagClick(e, "Tag")}
                >
                  {e}
                </p>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagGenerator;
