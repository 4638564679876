import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { handlePayment } from "../../redux/actions/paymentActions";
import logo from "../../assets/images/logo.png";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentOptions = () => {
  const [paymentOption, setPaymentOption] = useState([]);
  const [activePriceId, setActivePriceId] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const newUser = useSelector((state) => state.auth.newUser);
  useEffect(() => {
    // fetch payment options
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      toast.error("Create an Account first!");
      window.location.href = "/signup";
    }

    const planId = user?.plan_id;

    const subscriptionEnd = user?.subscriptionEnd;

    const dateFromString = new Date(subscriptionEnd);
    const currentDate = new Date();

    if ((dateFromString > currentDate)) {
      toast.error("You already have a plan!");
      // handlePortalSession();
      window.location.href = "/dashboard";
    }

    if (planId) {
      window.location.href = "/dashboard";
    }

    // const userDetails = axios.get(process.env.REACT_APP_BASE_URL + "/user/details", {
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //     },
    // });

    // if(userDetails.data?.subscritptionActive){
    //     setActivePriceId(userDetails.data.planId)
    // }

    const fetchPaymentOptions = async () => {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/stripe/fetch-plans"
      );
      console.log(response.data, "response");
      console.log(process.env.REACT_APP_TABLE_ID, "table id");
      setPaymentOption(response.data);
    };
    // setPaymentOption
    fetchPaymentOptions();
  }, [user]);

  const logoutHandler = () => {
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("refreshToken");
    // dispatch({
    //     type: "LOGOUT",
    // });

    navigate("/signup");
  }

  return (
    <>
      <div
        className={`px-5 py-3.5 flex  w-full justify-between items-center font-Inter`}
      >
        <div className="flex items-center space-x-3 w-[60%] max-w-[240px]">
          <img src={logo} className="w-3/4 h-auto" />
        </div>
        <div>
                    <button
                        type="text"
                        className="inline-flex items-center gap-x-2 text-[14px] font-semibold font-Inter px-4 rounded-lg border-[1px] border-[#D0D5DD] py-2 shadow-leadButton hover:shadow-navButton"
                        onClick={logoutHandler}
                    >
                        <p
                            className="text-sm font-semibold text-center text-[#344054]"
                        >
                            Sign out
                        </p>
                    </button>
                </div>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-1" />
      <div className="grid min-h-screen place-items-center"
      >
        <div className="items-center justify-center w-full">
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PK}
            customer-email={user?.email}
            customer-name={user?.name}
          >
          </stripe-pricing-table>
        </div>
      </div>
    </>
    // <div class="bg-gray-100 h-screen flex items-center justify-center">
    //   <div class="container mx-auto">
    //     <div class="flex flex-col items-center justify-center space-y-8 lg:flex-row lg:items-stretch lg:space-y-0">
    //       {paymentOption.map((plan, index) => (
    //         <div
    //           key={index}
    //           class="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-gray-200 rounded-lg lg:mx-4 dark:bg-gray-800 dark:border-gray-700"
    //         >
    //           <div class="flex-shrink-0">
    //             <h2 class="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-red-500 uppercase rounded-lg bg-gray-50 dark:bg-gray-700">
    //               PLAN {index + 1}
    //             </h2>
    //           </div>
    //           <div class="flex-shrink-0">
    //             <span class="pt-2 text-4xl font-bold text-gray-800 uppercase dark:text-gray-100">
    //               {plan.amount / 100} dollars/month
    //             </span>
    //           </div>
    //           <ul class="flex-1 space-y-4">
    //             <li class="text-gray-500 dark:text-gray-400">You will get</li>
    //             <li class="text-gray-500 dark:text-gray-400">{(plan.amount / 5)} Credits</li>
    //             <li class="text-gray-500 dark:text-gray-400"></li>
    //           </ul>

    //           <button
    //             onClick={() => handlePayment(plan.id)}
    //             class="inline-flex items-center justify-center px-4 py-2 font-semibold text-white uppercase transition-colors bg-red-500 rounded-lg hover:bg-red-700 focus:outline-none"
    //           >
    //             Start free
    //           </button>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  );

}

export default PaymentOptions;
