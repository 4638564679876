import React, { useRef, useState, useEffect } from "react";
import InfoHeader from "../../components/InfoHeader";
import TagSkeleton from "../../components/Skeleton/TagSkeleton";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { Link } from "react-router-dom";
import {
  AiOutlineEye,
  AiOutlineLike,
  AiOutlineDollarCircle,
} from "react-icons/ai";
import { formatNumber } from "../../helpers/formatNumber";
import { BiTimeFive } from "react-icons/bi";
import { MdMoneyOff } from "react-icons/md";
import Brief from "../../components/Brief";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { handleTagClick } from "../../helpers/utils";
import Tooltip from '@mui/material/Tooltip';
import { creditsData } from "../../redux/actions/paymentActions";

const TagExtractor = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const creditsDatas = useSelector((state) => state.stripe.creditsData);
  const credit_balance = creditsDatas?.credit_balance;
  const prevCreditsRef = useRef(credit_balance);

  useEffect(() => {
    // Calculate the change in credits
    const creditChange = credit_balance - prevCreditsRef.current;
    console.log(credit_balance, "creditChange")
    console.log(prevCreditsRef.current, "prevCreditsRef.current")
    // Display notification when credits_available changes
    if (creditChange !== 0) {

      const message = `${Math.abs(creditChange)} credits deducted for Tag Extraction`;
      if (creditChange < 0) {
        toast.success(message)
      }
    }

    // Update the ref to the current value for the next calculation
    prevCreditsRef.current = credit_balance;
  }, [credit_balance]);

  useEffect(() => {
    document.title = "Tag extractor - YTA Studio";
  }, []);

  useEffect(() => {
    dispatch(creditsData());
  }, []);

  function isValidYouTubeUrl(url) {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return regex.test(url);
  }

  const getTags = (videoUrl) => {

    if (!isValidYouTubeUrl(videoUrl)) {
      toast.error("Please enter a valid youtube url");
      return;
    }

    setLoading(true);
    authenticatedInstance
      .post(process.env.REACT_APP_BASE_URL + "/tag/extractor", {
        videoUrl: videoUrl,
        balanceReduction: 30,
        creditReduction: 3,
      })
      .then((res) => {

        if (res?.data?.status === false) {
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }
        console.log(res.data.stripeResult, "res.data.stripeResult")
        setData(res?.data);
        dispatch({
          type: "UPDATE_STRIPE",
          payload: res.data.stripeResult,
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        toast.error("Some error occured, please try again!");
        setLoading(false);
      });
  };
  return (
    <div className="p-3">
      <InfoHeader
        title={"Tag Extractor"}
        subtitle={"Extract tags from a youtube video"}
        buttonLabel={"Video URL"}
        placeholder={"https://youtu.be/YXsw_R"}
        generateFunction={getTags}
        creditsRequired={process.env.REACT_APP_TAG_EXTRACTOR_CREDITS}
        loading={loading}
        confirmationMessage={"This will extract the Tags for video url provided. It will cost you " + process.env.REACT_APP_TAG_EXTRACTOR_CREDITS + " credits for each request."}
        backButton={true}
        brief={true}
        briefTitle={"Why tag extractor ?"}
        briefData={[
          "Tags helps youtube's algorithm to understand the content of your video, making it easily discoverable to more audience",
          "Improved SEO for video",
          "Connect with your target audience easily",
          "Tags help categorize and organize your content. This not only benefits viewers who can find content related to their interests but also helps you, as a content creator, understand which themes and topics are most popular and should be prioritized in your future content.",
        ]}
      />

      {loading && <TagSkeleton />}
      <div className="flex gap-x-2 py-5">
        {data?.data?.tags?.length > 0 && (
          <div className="w-[55%] border border-gray-200 rounded-lg p-5 flex flex-col gap-y-5">
            <h1 className=" text-gray-700 font-semibold">
              Recommended tags for given youtube video
            </h1>

            <div className="flex gap-x-3 gap-y-3 items-center flex-wrap">
              {data?.data?.tags?.map((e, i) => {
                return (
                  <Tooltip title="Click to copy" placement="top-end">
                    <p
                      key={i}
                      className="px-4 py-1 rounded-lg bg-gray-100 text-gray-600 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleTagClick(e, "Tag")}
                    >
                      {e}
                    </p>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        )}
        {data?.videoInfo && (
          <div className=" w-[45%] ">
            <div className="flex flex-col pb-5 border border-gray-300 p-4 rounded-lg">
              <img
                src={data?.videoInfo?.thumbnailUrl}
                className="w-full rounded-lg aspect-video"
              />
              <div className="flex flex-col py-4 space-y-1 h-[40%]">
                <Link
                  to={data?.videoInfo?.url}
                  className="text-lg text-gray-800 font-bold hover:underline w-fit"
                >
                  {data?.videoInfo?.title.length > 35
                    ? data?.videoInfo?.title.substring(0, 35) + "..."
                    : data?.videoInfo?.title}
                </Link>
                <div className="flex items-center gap-x-2">
                  <BiTimeFive className="text-primary w-5 h-5 rounded-full" />
                  <p>
                    {data?.videoInfo?.duration.startsWith("00:")
                      ? data?.videoInfo?.duration.substr(3)
                      : data?.videoInfo?.duration}
                  </p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <Link
                    to={data?.videoInfo?.channelUrl}
                    className="subtitle hover:underline w-fit font-semibold"
                  >
                    {data?.videoInfo?.channelName}
                  </Link>
                  <p className="subtitle">
                    {formatNumber(data?.videoInfo?.numberOfSubscribers)}{" "}
                    Subscribers
                  </p>
                </div>
                <div className="flex space-x-3 subtitle items-center pb-3 py-1">
                  <p className="flex items-center space-x-2">
                    {" "}
                    <AiOutlineEye className="text-primary w-5 h-5 rounded-full" />{" "}
                    <p>{formatNumber(data?.videoInfo?.viewCount)} Views </p>
                  </p>
                  <p> | </p>
                  <p className="flex items-center space-x-2">
                    {" "}
                    <AiOutlineLike className="text-primary w-5 h-5 rounded-full" />{" "}
                    <p>{formatNumber(data?.videoInfo?.likes)} Likes </p>
                  </p>
                  {data?.videoInfo?.isMonetized ? (
                    <>
                      <p> | </p>
                      <p className="flex items-center space-x-2">
                        {" "}
                        <AiOutlineDollarCircle className="text-primary w-5 h-5 rounded-full" />{" "}
                        <p>Monetized</p>
                      </p>
                    </>
                  ) : (
                    <>
                      <p> | </p>
                      <p className="flex items-center space-x-2">
                        {" "}
                        <MdMoneyOff className="text-primary w-5 h-5 rounded-full" />{" "}
                        <p>Unmonetized</p>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TagExtractor;
